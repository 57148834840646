import { createRouter, createWebHistory } from "vue-router";
import JourneyPlanner from "@/components/JourneyPlanner.vue";
import SearchResults from "@/components/1-JourneyPlanner/SearchResults.vue";
import MobileDateTimeSelector from "@/components/1-JourneyPlanner/MobileDateTimeSelector.vue";
import TicketSummary from "@/components/3-Basket/YourSelection.vue";
import SeatReservation from "@/components/3-Basket/SeatReservation.vue";
import YourBasket from "@/components/3-Basket/YourBasket.vue";
import Checkout from "@/components/4-Checkout/Checkout.vue";
import PaymentConfirmation from "@/components/4-Checkout/PaymentConfirmation.vue";
import { fetchAuthSession } from "aws-amplify/auth";
import SecureLogin from "@/components/4-Checkout/SecureLogin.vue";
import type { RetailInstanceTypeService } from "@/services/RetailInstanceTypeService";
import { Hub } from "aws-amplify/utils";

const constructRouter = (instanceTypeSvc: RetailInstanceTypeService) => {
    const router = createRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        routes: [
            {
                path: "/",
                name: "home",
                component: JourneyPlanner,
                props: {
                    mode: "default",
                    enableExpand: false,
                },
            },
            {
                path: "/results",
                name: "searchresults",
                component: SearchResults,
                props: (route) => ({ request: route.query }),
            },
            {
                path: "/about",
                name: "about",
                component: () => import("../views/AboutView.vue"),
            },
            {
                path: "/date-time-selection",
                name: "date-time",
                component: MobileDateTimeSelector,
            },
            {
                path: "/your-selection",
                name: "your-selection",
                component: TicketSummary,
            },
            {
                path: "/seat-reservation",
                name: "seat-reservation",
                component: SeatReservation,
            },
            {
                path: "/your-basket",
                name: "your-basket",
                component: YourBasket,
            },
            {
                name: "checkout",
                path: "/checkout",
                component: Checkout,
            },
            {
                name: "login",
                path: "/login",
                component: SecureLogin,
                beforeEnter: (async () => {
                    const session = await fetchAuthSession();
                    if (session.tokens !== undefined) { // if we're logged in
                        return { name: "home" };
                    }
                    return true; // let the user continue to their intended route
                }),
            },
            {
                path: "/payment-confirmation",
                name: "payment-confirmation",
                component: PaymentConfirmation,
            },
        ],
    });

    router.beforeEach(async (to, from, next) => {
        const session = await fetchAuthSession();
        if (to.name !== "login" && session.tokens === undefined && instanceTypeSvc.isCorporate()) {
            next({ name: "login", query: { redirectUri: to.fullPath } });
        }
        next();
    });

    Hub.listen("auth", (ev) => {
        const { payload } = ev;
        if (payload.event === "signedOut") {
            const currentRoute = router.currentRoute.value;
            // if we're on checkout, send back to basket
            if (currentRoute.name === "checkout") {
                router.push({ name: "your-basket" });
            }
            if (instanceTypeSvc.isCorporate()) {
                // what page are we currently on?
                router.push({ name: "login", query: { redirectUri: currentRoute.fullPath } });
            }
        }
    });

    return router;
};

export default constructRouter;

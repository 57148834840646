import { v4 as uuidv4 } from "uuid";
import { useAlertStore } from "@/stores/alert-store";
import type { ProblemDetail, ValidationProblemDetail } from "@/api/ProblemDetail";
import { logApiError } from "@/utilities/LoggerUtilities";

export enum AlertTypes {
    Success = "success",
    Info = "info",
    Warning = "warning",
    Error = "error"
}

export interface Alert {
    key: string,
    created: Date;
    type: AlertTypes;
    title?: string,
    message?: string;
    items?: { [key: string]: string[]; },
    componentId?: string;
}

function handleAlert(type: AlertTypes, value: any): void {
    const newAlert: Alert = {
        key: uuidv4(),
        created: new Date(),
        type,
        componentId: undefined,
    };

    let vpd: ValidationProblemDetail | null = null;
    let pd: ProblemDetail | null = null;
    let error: Error | null = null;

    if (Object.prototype.hasOwnProperty.call(value, "message")
        && Object.prototype.hasOwnProperty.call(value, "stack")) {
        error = value as Error;
    } else if (Object.prototype.hasOwnProperty.call(value, "items")) {
        vpd = value as ValidationProblemDetail;
    } else {
        pd = value as ProblemDetail;
    }

    if (vpd !== null) {
        newAlert.title = vpd.title;
        newAlert.items = vpd.errors;
        logApiError(vpd);
    } else if (pd !== null) {
        newAlert.title = pd.title;
        newAlert.message = pd.detail;
        logApiError(pd);
    } else if (error !== null) {
        newAlert.message = error.message;
    } else {
        newAlert.message = value;

        switch (type) {
            case AlertTypes.Info:
                // eslint-disable-next-line no-console
                console.info(alert);
                break;
            case AlertTypes.Warning:
                console.warn(alert);
                break;
            case AlertTypes.Error:
                console.error(alert);
                break;
            default:
                console.error("Unknown alert type", type);
                break;
        }
    }
    const store = useAlertStore();
    store.addAlert(newAlert);
}

export function handleSuccess(value: any): void {
    handleAlert(AlertTypes.Success, value);
}

export function handleInfo(value: any): void {
    handleAlert(AlertTypes.Info, value);
}

export function handleWarning(value: any): void {
    handleAlert(AlertTypes.Warning, value);
}

export function handleError(value: any): void {
    handleAlert(AlertTypes.Error, value);
}

export function clearAlerts(): void {
    const store = useAlertStore();
    store.clearAlerts();
}

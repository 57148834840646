<script setup lang="ts">
import { ref, toRefs } from "vue";
import type { Ref } from "vue";
import type { JourneyViewType } from "@/models/basket/JourneyViewType";
import type { TicketDetailsByKey, FaresByKeyModel, TicketRestrictionSummary } from "@/models/JourneySelectorViewModel";
import { useDateFormat } from "@vueuse/core";

const props = withDefaults(defineProps<{
    fareType: JourneyViewType,
    journeyFare: FaresByKeyModel | null,
    ticketDetail?: TicketDetailsByKey | null;
    restrictionDetails: TicketRestrictionSummary | null,
}>(), {
    ticketDetail: null,
    destinationStation: "",
    restrictionDetails: null,
});

const { journeyFare } = toRefs(props);
const { ticketDetail } = toRefs(props);
const { restrictionDetails } = toRefs(props);

const dialog: Ref<boolean> = ref(false);

function formatDate(date: string | undefined) {
    return useDateFormat(date, "DD MMMM YYYY").value;
}
function showDialog() {
    dialog.value = true;
}

defineExpose({
    showDialog,
    dialog, // for testing only
});
</script>

<template>
    <div class="text-center">
        <v-dialog v-model="dialog" :persistent="true" :scrollable="true" width="800px">
            <v-card>
                <v-card-title class="text-center">
                    <a class="float-end" @click="dialog = false">
                        <font-awesome-icon icon="fa-5 fa-circle-xmark"></font-awesome-icon>
                    </a>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="bg-white border rounded-lg pa-2 text-center fare-header">
                        {{ ticketDetail?.name || journeyFare?.displayName}}
                    </div>
                    <div class="bg-white border rounded-lg pa-1 mb-2">
                        <v-row class="pa-2">
                            <v-col>
                                <span>Ticket for</span>
                            </v-col>
                            <v-col>
                                <span> {{ journeyFare?.originName }} - {{ journeyFare?.destinationName }}</span>
                            </v-col>
                        </v-row>
                        <hr>
                        <v-row class="pa-2" v-if="restrictionDetails !== null">
                            <v-col>
                                <span>Restriction code</span>
                            </v-col>
                            <v-col>
                                <span>{{ journeyFare?.restrictionCode }}
                                    (<a target="_blank" class="mx-1"
                                        :href="restrictionDetails?.linkToDetailPage">details
                                        <font-awesome-icon icon="fa-duotone fa-up-right-from-square"></font-awesome-icon>
                                    </a>)
                                </span>
                            </v-col>
                        </v-row>
                        <hr>
                        <v-row class="pa-2">
                            <v-col>
                                <span>Ticket code</span>
                            </v-col>
                            <v-col>
                                <span>
                                    <a target="_blank" :href="`https://www.nationalrail.co.uk/ticket-types/tickets/${journeyFare?.ticketCode}`">
                                        {{ journeyFare?.ticketCode }}
                                    </a>
                                </span>
                            </v-col>
                        </v-row>
                        <hr>
                        <v-row class="pa-2">
                            <v-col>
                                <span>Valid on</span>
                            </v-col>
                            <v-col>
                                <span>{{ formatDate(journeyFare!.ticketDate) }}</span>
                            </v-col>
                        </v-row>
                        <hr>
                        <v-row class="pa-2">
                            <v-col>
                                <span>Can I break my outward journey?</span>
                            </v-col>
                            <v-col>
                                <span>{{ ticketDetail?.breakOutbound ? "Yes" : "No" }}</span>
                            </v-col>
                        </v-row>
                        <div v-if="journeyFare?.type !== 'Single'">
                            <hr>
                            <v-row class="pa-2">
                                <v-col>
                                    <span>Can I break my inward journey?</span>
                                </v-col>
                                <v-col>
                                    <span>{{ ticketDetail?.breakInbound ? "Yes" : "No" }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="bg-white border rounded-lg pa-2 mb-2" v-if="restrictionDetails !== null">
                        <div class="row">
                            <div class="text-subtitle-2 font-weight-bold pb-1">Restriction Details</div>
                            <div class="text-subtitle-2 font-weight-bold pb-1">Applicable Days</div>
                            <div v-html="restrictionDetails.applicableDays"></div>
                            <div v-if="restrictionDetails.easements !== null">
                                <div class="text-subtitle-2 font-weight-bold pb-1">Easements</div>
                                <div v-html="restrictionDetails.easements"></div>
                            </div>
                            <div v-if="restrictionDetails.seasonalVariations !== null">
                                <div class="text-subtitle-2 font-weight-bold pb-1">Seasonal Variations</div>
                                <div v-html="restrictionDetails.seasonalVariations"></div>
                            </div>
                            <div v-if="restrictionDetails.notes !== null">
                                <div class="text-subtitle-2 font-weight-bold pb-1">Additional Details</div>
                                <div v-html="restrictionDetails.notes"></div>
                            </div>
                            <div v-for="(item, i) in restrictionDetails?.restrictionDetails" :key="i">
                                <div v-if="item?.outDetails">
                                    <div class="text-subtitle-2 font-weight-bold pb-1">Outbound</div>
                                    <div v-html="item?.outDetails"></div>
                                </div>
                                <div v-if="item?.inDetails">
                                    <div class="text-subtitle-2 font-weight-bold pb-1">Inbound</div>
                                    <div v-html="item?.inDetails"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white border rounded-lg pa-2 mb-2">
                        <div class="row">
                            <div class="text-subtitle-2 font-weight-bold pb-1">Description</div>
                            <div v-html="ticketDetail?.description"></div>
                        </div>
                    </div>

                    <div class="bg-white border rounded-lg pa-2 mb-2" v-if="ticketDetail?.discounts !== null">
                        <div class="row">
                            <div class="text-subtitle-2 font-weight-bold pb-1">Discounts</div>
                            <div v-html="ticketDetail?.discounts" class="discounts"></div>
                        </div>
                    </div>

                    <div class="bg-white border rounded-lg pa-2 mb-2">
                        <div class="row">
                            <div class="text-subtitle-2 font-weight-bold pb-1">Changes to travel plans</div>
                            <div v-html="ticketDetail?.changesToTravelPlans"></div>
                        </div>
                    </div>

                    <div class="bg-white border rounded-lg pa-2 mb-2">
                        <div class="row">
                            <div class="text-subtitle-2 font-weight-bold pb-1">Break of journey</div>
                            <div class="text-subtitle-2 font-weight-bold pb-1">Outbound</div>
                            <div v-html="ticketDetail?.breaksOfJourneyOut"></div>
                            <div v-if="journeyFare?.type !== 'Single'">
                                <div class="text-subtitle-2 font-weight-bold pb-1">Inbound</div>
                                <div v-html="ticketDetail?.breaksOfJourneyIn"></div>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white border rounded-lg pa-2">
                        <div class="row">
                            <div class="text-subtitle-2 font-weight-bold pb-1">Refunds</div>
                            <div v-html="ticketDetail?.refunds"></div>
                        </div>
                    </div>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="primary-button" variant="text" @click="dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped lang="scss">
.card {
    width: 800px;
}

.v-card {
    .v-card-text {
        background-color: var(--vt-light-gray);
        height: 600px;
    }

    .v-divider {
        margin: 0;
    }
}

.card-section {
    background-color: var(--vt-light-gray);
}

.v-card-actions {
    button {
        background-color: var(--vt-c-orange);
        color: #fff;
        text-transform: capitalize;
    }
}
.fare-header {
    font-size:18px;
}
:deep(.discounts ul li),
:deep(.row ul) {
    margin-left: 1.25rem;
}
</style>

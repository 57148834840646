import { type IGQLBookingClient, GQLBookingClient } from "@/api/GQLBookingClient";
import type { IPriceBreakdown } from "@/models/graphql/PriceBreakdownResponse";
import ApiClient from "@/api/ApiClient";
import type { IBookingOverviewResponse } from "@/models/graphql/BookingOverviewResponse";

export class GQLBookingService {
    gqlBookingClient: IGQLBookingClient;

    constructor(gqlBookingClient: IGQLBookingClient) {
        this.gqlBookingClient = gqlBookingClient;
    }

    async createGQLBooking(fareKeys: string[], outkey: string, inKey: string, searchId: string): Promise<IBookingOverviewResponse> {
        return this.gqlBookingClient.addGQLBooking(null, fareKeys, outkey, inKey, searchId);
    }

    async addGQLReservation(bookingId: string, tripId: string, seatFacingDirection: string, seatPosition: string, coachType: string, features: string[]): Promise<string> {
        return this.gqlBookingClient.makeGQLReservation(bookingId, tripId, seatFacingDirection, seatPosition, coachType, features);
    }

    async addToGQLBasket(bookingId: string, fareKeys: string[], outkey: string, inKey: string, searchId: string): Promise<IBookingOverviewResponse> {
        return this.gqlBookingClient.addGQLBooking(bookingId, fareKeys, outkey, inKey, searchId);
    }

    async getGQLBookingOverview(bookingId: string): Promise<IBookingOverviewResponse> {
        return this.gqlBookingClient.getGQLBookingOverview(bookingId);
    }

    async deleteGQLBooking(bookingId: string): Promise<string> {
        return this.gqlBookingClient.emptyGQLBasket(bookingId);
    }

    async deleteGQLTrip(bookingId: string, tripId: string): Promise<string> {
        return this.gqlBookingClient.removeGQLTrip(bookingId, tripId);
    }

    async computePriceBreakdown(searchId: string, outboundJourneyKey: string, bookingId: string|null, fareKeys: string[], inboundJourneyKey?: string): Promise<IPriceBreakdown> {
        return this.gqlBookingClient.gqlPriceBreakdown(searchId, outboundJourneyKey, bookingId, fareKeys, inboundJourneyKey);
    }
}

const gqlBookingApiClient = new GQLBookingClient(new ApiClient());
export const gqlBookingService = new GQLBookingService(gqlBookingApiClient);

<script setup lang="ts">

import { ref, watch } from "vue";
import type { Ref } from "vue";
import { useField } from "vee-validate";
import { useJourneyStore } from "../../stores/journey-store";
import RailCardSelector from "./RailCardSelector.vue";

const store = useJourneyStore();
const editingPassengers: Ref<Boolean> = ref<Boolean>(false);
const maximumPassengers: number = 9;
let remainingPassengers: number = 9;
let remainingChildren: number = 9;

const adultsField = useField<number>("adults", () => true);
const adults = adultsField.value;
const childrenField = useField<number>("children", () => true);
const children = childrenField.value;

watch(adults, async (newSelectedAdults) => {
    remainingChildren = maximumPassengers - newSelectedAdults;
    store.query.adults = newSelectedAdults;
});

watch(children, async (newSelectedChildren) => {
    remainingPassengers = maximumPassengers - newSelectedChildren;
    store.query.children = newSelectedChildren;
});

</script>

<template>
    <div>
        <div class="row">
            <div class="col-1">
                <label class="form-label">Passengers </label>
            </div>
            <div class="col-8">
                <div class="section-header">Passenger details</div>
                <v-btn id="railcard-info-button" type="button" class="btn form-select"
                       @click="editingPassengers = !editingPassengers">
                    <div>
                        <font-awesome-icon icon="fa-user-friends" ></font-awesome-icon>
                        {{ store.query.adults }}
                        {{ store.query.adults === 1 ? "adult" : "adults" }}
                        <span v-if="store.query.children > 0"> <br>
                            with {{ store.query.children }} {{ store.query.children === 1 ? "child" : "children" }}
                        </span>
                        <span><br>
                            with <font-awesome-icon icon="fa-address-card" ></font-awesome-icon>
                            {{ store.query.railCard ? store.query.railCard.description : "no railcards " }}
                        </span>
                    </div>
                </v-btn>
            </div>
        </div>
        <div v-if="editingPassengers || store?.hasRailCard">
            <div class="row passenger-editor">
                <div class="adult-editor">
                    <label class="form-label">Adults (16+)</label>
                    <select id="adult-selector" class="form-select" v-model.number="adults"
                            :class="{ 'p-invalid': adultsField.errorMessage.value }">
                        <option v-for="n in (remainingPassengers+1)" :key="n">
                            {{ n-1 }}
                        </option>
                    </select>
                </div>
                <div class="child-editor">
                    <label class="form-label">Children (5-15yrs)</label>
                    <select id="children-selector" class="form-select" v-model.number="children"
                            :class="{ 'p-invalid': childrenField.errorMessage.value }">
                        <option v-for="n in (remainingChildren+1)" :key="n">
                            {{ n-1 }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <small>Under 5s travel free.</small>
        <RailCardSelector :number-of-passengers="store.query.adults+store.query.children" ></RailCardSelector>
    </div>
</template>

<style scoped lang="scss">

#railcard-info-button {
    text-align: center;
    border: 1px solid var(--vt-c-lightgray);
    padding: 10px 18px;
    border-radius: 20px;
    box-shadow: none;
    line-height: 1.5;
    text-transform: capitalize;
    height: fit-content;
}

select#adult-selector, select#children-selector {
    -webkit-appearance: auto;
    padding: 10px;
    border: 1px solid var(--vt-c-lightgray);
    border-radius: 5px;
}

.child-editor {
    padding-left: 20px;
}

.passenger-editor {
    padding-bottom: 15px;
    display: flex;
    select#adult-selector, select#children-selector {
        width: 125px;
        border-radius: 20px;
    }
}

@media only screen and (max-width: 760px) {
    #railcard-info-button {
        margin-top: 10px;
        width: 315px;
    }
    .passenger-editor {
        display: flex;
        padding-top: 15px;
        padding-bottom: 5px;
        select#adult-selector, select#children-selector {
            width: 145px;
        }
        .form-label {
            display:block;
            color: var(--vt-c-orange);
            font-size: 0.8rem;
            float: left;
            padding-left: 10px;
        }
        .child-editor {
            padding-left: 5px;
        }
    }

    small {
        color: var(--vt-c-black-mute)
    }
}

</style>

import { computed, type Ref, ref } from "vue";
import { defineStore } from "pinia";
import type { Alert } from "@/services/AlertService";

export const useAlertStore = defineStore("alert", () => {
    const items: Ref<Array<Alert>> = ref([]);
    const alerts = computed(() => items);
    function itemExists(alert: Alert): boolean {
        return items.value.some(({ type, message }) => type === alert.type && message === alert.message);
    }

    function addAlert(alert: Alert) {
        if (!itemExists(alert)) {
            items.value.push(alert);
        }
    }

    function removeAlert(key: string) {
        items.value = items.value.filter((x) => x.key !== key);
    }

    function clearAlerts() {
        items.value = [];
    }

    return {
        alerts,
        addAlert,
        removeAlert,
        clearAlerts,
    };
});

import type { SimplePlaceModel } from "@/models/basket/SimplePlaceModel";
import type { RailCardsResponse } from "@/models/remote/RailCardsResponse";
import type { GetJourneysRequest } from "@/models/remote/GetJourneysRequest";
import { JourneyType } from "@/models/JourneyType";
import { JourneyTimeType } from "@/models/JourneyTimeType";
import { ViaAvoidType } from "@/models/ViaAvoidType";
import type { GetPlacesResponse } from "@/models/remote/GetPlacesResponse";
import type { ValidateRailcardResponse } from "@/models/remote/ValidateRailcardResponse";
import type { JourneyFaresResponse } from "@/models/basket/JourneyFaresResponse";
import type { JourneySelectorViewModel } from "@/models/JourneySelectorViewModel";
import type { IApiClient } from "./ApiClient";

export interface IRetailApiClient {
    getRailcards(): Promise<RailCardsResponse>;
    validateRailcard(code: string, adults: number, children: number): Promise<ValidateRailcardResponse>;
    getPlaces(query: string, signal: AbortSignal): Promise<GetPlacesResponse>;
    getPlace(id: string): Promise<SimplePlaceModel | undefined>;
    getJourneys(count: number, request: GetJourneysRequest): Promise<JourneySelectorViewModel>;
    getJourneyFare(query: string): Promise<JourneyFaresResponse>;
}

const railcardsEndpoint: string = "/api/railcards";
const placesEndpoint: string = "/api/places";
const journeysEndpoint: string = "/api/journeys";
const journeyFaresEndpoint: string = "/api/journeyFares";

export class RetailApiClient implements IRetailApiClient {
    retailApiClient: IApiClient;

    constructor(retailApiClient: IApiClient) {
        this.retailApiClient = retailApiClient;
    }

    async getRailcards(): Promise<RailCardsResponse> {
        return this.retailApiClient.get<RailCardsResponse>(railcardsEndpoint);
    }

    async validateRailcard(code: string, adults: number, children: number): Promise<ValidateRailcardResponse> {
        const params = new URLSearchParams(
            {
                code,
                adults: adults.toString(),
                children: children.toString(),
            },
        );

        return this.retailApiClient.get<ValidateRailcardResponse>(`${railcardsEndpoint}/validate?${params.toString()}`);
    }

    async getPlaces(query: string, signal: AbortSignal): Promise<GetPlacesResponse> {
        return this.retailApiClient.get<GetPlacesResponse>(`${placesEndpoint}?query=${encodeURIComponent(query)}&sort=Relevance`, {
            signal,
        });
    }

    async getPlace(id: string): Promise<SimplePlaceModel | undefined> {
        return this.retailApiClient.get<SimplePlaceModel>(`${placesEndpoint}/${id}`);
    }

    async getJourneys(count: number, request: GetJourneysRequest): Promise<JourneySelectorViewModel> {
        const { from } = request;
        const { to } = request;

        const params = new URLSearchParams(
            {
                count: count.toString(),
                from,
                to,
                adults: request.adults.toString(),
                children: request.children.toString(),
            },
        );

        if (request.railcard) {
            params.append("railcard", request.railcard);
        }

        if (request.journeyType === JourneyType.OpenReturn) {
            params.append("return-open", "true");
        }

        let arriving: string | undefined;
        let departing: string | undefined;

        if (request.outboundTimeType === JourneyTimeType.ArrivingBefore) {
            arriving = request.outboundDate;
        } else if (request.outboundTimeType === JourneyTimeType.DepartingAt) {
            departing = request.outboundDate;
        }

        if (arriving) {
            params.append("arriving", arriving);
        } else if (departing) {
            params.append("departing", departing);
        }

        let returnArriving: string | undefined;
        let returnDeparting: string | undefined;

        if (request.returnTimeType === JourneyTimeType.ArrivingBefore) {
            returnArriving = request.returnDate;
        } else if (request.returnTimeType === JourneyTimeType.DepartingAt) {
            returnDeparting = request.returnDate;
        }

        if (returnArriving) {
            params.append("return-arriving", returnArriving);
        } else if (returnDeparting) {
            params.append("return-departing", returnDeparting);
        }

        if (request.viaAvoid && request.viaAvoidStation) {
            params.append(request.viaAvoid === ViaAvoidType.Via
                ? "outbound-via-stop"
                : "outbound-avoid-stop", request.viaAvoidStation);

            if (request.journeyType === JourneyType.Return) {
                params.append(request.viaAvoid === ViaAvoidType.Via
                    ? "inbound-via-stop"
                    : "inbound-avoid-stop", request.viaAvoidStation);
            }
        }

        return this.retailApiClient.get<JourneySelectorViewModel>(`${journeysEndpoint}/journeyselector?${params.toString()}`);
    }

    async getJourneyFare(query: string): Promise<JourneyFaresResponse> {
        return this.retailApiClient.get<JourneyFaresResponse>(`${journeyFaresEndpoint}?furtherDetails=${encodeURIComponent(query)}`);
    }
}

import { type Ref, ref } from "vue";
import { defineStore } from "pinia";
import type { IGQLBookingOverview, IGQLTripsOverview } from "@/models/graphql/BookingOverviewResponse";

export const useBookingData = defineStore("bookingData", () => {
    const bookingData: Ref<IGQLBookingOverview|null> = ref(null);

    function checkExistingBooking(): string|null {
        let bookingId: string | null = null;
        if (bookingData.value !== null) {
            const bookingObject = bookingData.value;
            const currentDate = new Date();
            const expirationToken = new Date(bookingObject.bookingExpiration);
            const isValid = expirationToken > currentDate;
            if (isValid) {
                bookingId = bookingObject.id;
            } else {
                bookingId = null;
                bookingData.value = null;
            }
        }
        return bookingId;
    }

    function getExistingBookingFee() {
        let bookingFee: number | null = null;
        if (bookingData.value !== null) {
            const bookingObject = bookingData.value;
            bookingFee = bookingObject.bookingFee as number;
        }
        return bookingFee;
    }

    function bookedTrips(): IGQLTripsOverview[]|null {
        if (bookingData.value !== null) {
            const bookingObject = bookingData.value;
            return bookingObject.trips;
        }
        return null;
    }

    function clearBookingData() {
        bookingData.value = null;
    }

    return {
        bookingData,
        checkExistingBooking,
        getExistingBookingFee,
        bookedTrips,
        clearBookingData,
    };
}, {
    persist: [
        {
            storage: localStorage,
            pick: ["bookingData"],
        },
    ],
});

<script setup lang="ts">
import type { Ref } from "vue";
import {
    ref, watch, computed,
} from "vue";
import {
    ReservationPreferences, SeatDirection, SeatPosition, CoachType, FareDetailsFlag, ReservationRequestModel,
} from "@/models/basket/ReservationPreferences";
import type { JourneyFaresResponse } from "@/models/basket/JourneyFaresResponse";
import { handleError } from "@/services/AlertService";
import { useField } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import { gqlBookingService } from "@/services/GQLService";
import { useBookingData } from "@/stores/booking-storage";

const bookingStore = useBookingData();
const isLoading: Ref<boolean> = ref(false);
const reservationTypeField = useField<boolean>("hasOptedForReservation");
const hasOptedForReservation = reservationTypeField.value;
const tableSeatSelected:Ref<boolean> = ref(false);
const nearLuggageRack:Ref<boolean> = ref(false);
const powerSocket:Ref<boolean> = ref(false);
const journeyFareData: Ref<JourneyFaresResponse | undefined> = ref(undefined);
const nearToilet:Ref<boolean> = ref(false);
const selectedSeatDirection: Ref<any> = ref(SeatDirection.NoPreference);
const selectedPosition: Ref<any> = ref(SeatPosition.NoPreference);
const selectedCoachType: Ref<any> = ref(CoachType.NoPreference);
const toggleModel: Ref<any> = ref("");
const seatReservationRequired: Ref<boolean> = ref(false);
const route = useRoute();
const router = useRouter();
const features: Ref<Array<string>> = ref([]);
const seatPositions = computed(() => [SeatPosition.NoPreference, SeatPosition.Aisle, SeatPosition.Window]);
const seatDirection = computed(() => [SeatDirection.NoPreference, SeatDirection.BackwardFacing, SeatDirection.ForwardFacing]);
const coachType = computed(() => [CoachType.NoPreference, CoachType.Quiet]);

function toggleTableSeat() {
    tableSeatSelected.value = !tableSeatSelected.value;
    if (tableSeatSelected.value) {
        features.value.push(ReservationRequestModel.TableSeat);
    } else {
        features.value.filter((e) => e !== ReservationRequestModel.TableSeat);
    }
}
function toggleLuggageRack() {
    nearLuggageRack.value = !nearLuggageRack.value;
    if (nearLuggageRack.value) {
        features.value.push(ReservationRequestModel.NearLuggae);
    } else {
        features.value.filter((e) => e !== ReservationRequestModel.NearLuggae);
    }
}
function togglePowerSocket() {
    powerSocket.value = !powerSocket.value;
    if (powerSocket.value) {
        features.value.push(ReservationRequestModel.PowerSocket);
    } else {
        features.value.filter((e) => e !== ReservationRequestModel.PowerSocket);
    }
}
function toggleNearToilet() {
    nearToilet.value = !nearToilet.value;
    if (nearToilet.value) {
        features.value.push(ReservationRequestModel.NearToilet);
    } else {
        features.value.filter((e) => e !== ReservationRequestModel.NearToilet);
    }
}

async function applyPreferences(bookingDetailId: string, bookingId: string) {
    isLoading.value = true;
    const position = selectedPosition.value === SeatPosition.NoPreference ? null : selectedPosition.value;
    // do we need to update the backend to accept coach type ?
    const coach = selectedCoachType.value === CoachType.NoPreference ? null : selectedCoachType.value;
    const direction = selectedSeatDirection.value === SeatDirection.NoPreference ? null : selectedSeatDirection.value;
    try {
        await gqlBookingService.addGQLReservation(bookingId, bookingDetailId, direction, position, coach, features.value);
        isLoading.value = false;
    } catch (error) {
        handleError(error);
        isLoading.value = false;
    }
    await router.push({ path: "/your-basket/", query: { bookingId } });
}

async function createBooking() {
    const fareKeys = route.query.fareKeys as string[];
    const outKey = route.query.outKey as string;
    const inKey = route.query.inKey as string;
    const searchId = route.query.searchId as string;
    let bookingDetails: any;
    try {
        bookingDetails = await gqlBookingService.createGQLBooking(fareKeys, outKey, inKey, searchId);
    } catch (error) {
        handleError(error);
    }
    bookingStore.bookingData = bookingDetails.booking;
    if (seatReservationRequired.value || hasOptedForReservation.value) {
        await applyPreferences(bookingDetails.booking.trips[0].id, bookingDetails.booking.id);
    } else {
        await router.push({ path: "/your-basket/", query: { bookingId: bookingDetails.booking.id } });
    }
}

async function addToBasket(bookingId: string) {
    let bookingDetails: any;
    const fareKeys = route.query.fareKeys as string[];
    const outKey = route.query.outKey as string;
    const inKey = route.query.inKey as string;
    const searchId = route.query.searchId as string;
    try {
        bookingDetails = await gqlBookingService.addToGQLBasket(bookingId, fareKeys, outKey, inKey, searchId);
    } catch (error) {
        handleError(error);
    }
    bookingStore.bookingData = bookingDetails.booking;
    if (seatReservationRequired.value || hasOptedForReservation.value) {
        await applyPreferences(bookingDetails.booking.trips[1].id, bookingDetails.booking.id);
    } else {
        await router.push({ path: "/your-basket/", query: { bookingId: bookingDetails.booking.id } });
    }
}

function submitPreferences() {
    const bookingId = bookingStore.checkExistingBooking();
    if (bookingId) {
        addToBasket(bookingId);
    } else {
        createBooking();
    }
}

watch(journeyFareData, () => {
    seatReservationRequired.value = journeyFareData.value?.flags.indexOf(FareDetailsFlag.RequiresSeatReservation) > -1;
});
watch(toggleModel, () => {
    hasOptedForReservation.value = toggleModel.value;
});
</script>

<template>
    <div>
        <v-overlay v-model="isLoading"
                   contained
                   class="align-center justify-center">
            <v-progress-circular
                indeterminate
                :size="70"
                :width="7"
                color="primary">
            </v-progress-circular>
        </v-overlay>
        <v-container class="pa-0 pa-md-2 pb-5">
            <v-row class="pl-10 pt-10 pb-10 pr-10">
                <v-col cols="12">
                    <div class="head-section pb-10">
                        <v-switch
                            v-model="toggleModel"
                            hide-details
                            color="#FF7962"
                        ></v-switch>
                        <div class="horizontal-fields radio-type default-options pb-5"  v-if="!seatReservationRequired">
                            <h2>Would you like to reserve a seat?</h2>
                            <div class="pl-5 radio-option">
                                <input class="form-control" type="radio" name="journey-type" id="yes"
                                       :value="true" v-model="hasOptedForReservation">
                                <label class="form-check-label" for="yes">Yes</label>
                            </div>
                            <div class="radio-option">
                                <input class="form-control" type="radio" name="journey-type" id="no"
                                       :value="false" checked="true" v-model="hasOptedForReservation">
                                <label class="form-check-label" for="no">No</label>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div v-if="hasOptedForReservation || seatReservationRequired" class="horizontal-fields reservation default-options pb-5">
                        <h2 class="pb-2">Seat Reservations</h2>
                        <div v-if="seatReservationRequired">Note: Reservations are compulsory on this journey.</div>
                        <v-divider></v-divider>

                        <div class="preferences-section pt-5 pb-5">
                            <div class="row selected-preferences">
                                <div>
                                    <v-select
                                        label="Seat Direction"
                                        :items="seatDirection"
                                        v-model="selectedSeatDirection"
                                    ></v-select>
                                </div>
                                <div>
                                    <v-select
                                        label="Position"
                                        :items="seatPositions"
                                        v-model="selectedPosition"
                                    ></v-select>
                                </div>
                                <div>
                                    <v-select
                                        label="Coach Type"
                                        :items="coachType"
                                        v-model="selectedCoachType"
                                    ></v-select>
                                </div>

                            </div>

                            <div class="optional-preferences">
                                <v-checkbox :label="ReservationPreferences.TableSeat" @click="toggleTableSeat()"></v-checkbox>
                                <v-checkbox :label="ReservationPreferences.NearLuggae" @click="toggleLuggageRack()"></v-checkbox>
                                <v-checkbox :label="ReservationPreferences.PowerSocket" @click="togglePowerSocket()"></v-checkbox>
                                <v-checkbox :label="ReservationPreferences.NearToilet" :v-model="nearToilet" @click="toggleNearToilet()"></v-checkbox>
                            </div>
                            <div class="pb-5">We will request seats with your selected preferences, though they cannot always be guaranteed by the train operator.
                                Seating information will be confirmed upon reaching the shopping basket.
                            </div>
                            <v-divider></v-divider>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <!-- <RouterLink class="nav-link" :to="`/your-basket`">-->
            <v-btn class="primary-button ml-5 mb-5" @click="submitPreferences()">Apply preferences</v-btn>
            <!-- </RouterLink>-->
        </v-container>
    </div>
</template>

<style scoped lang="scss">
.v-input.v-input--horizontal {
    width: 200px;
    padding-left: 5px;
}
.row.selected-preferences {
    display: inline-flex;
}
:deep(.v-selection-control--dirty .v-selection-control__input > .v-icon) {
    color: var(--vt-c-orange);
}
.optional-preferences {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    .v-input.v-checkbox {
        flex: 1 1 calc(50%);
    }
}
.v-container {
    border: 3px solid var(--vt-c-orange);
    border-radius: 5px;
    margin-top: 5rem;
    min-width: 1000px;
}
span.v-chip.v-theme--light.v-chip--density-default.v-chip--size-default.v-chip--variant-tonal {
    background-color: var(--vt-c-orange);
    color: var(--vt-c-white);
}
button.primary-button {
    box-shadow: none
}
.v-input.v-input--horizontal.v-input--center-affix.v-input--density-default.v-locale--is-ltr.v-switch {
    display: none;
}
.radio-type input[type=radio]:checked + label {
    background-color: var(--vt-c-orange);
    color: var(--vt-c-white);
}

.radio-type label {
    font-weight: normal;
    display: inline-block;
    background-color: transparent;
    border: 2px solid var(--vt-c-orange);
    border-radius: 5px;
    color: var(--vt-c-orange);
    cursor: pointer;
    height: 35px;
    width: 110px;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 20px;
}
input.form-control {
    display: none;
}
.radio-type label {
    text-align: center;
}
.horizontal-fields.reservation {
    display: block;
}
@media only screen and (max-width: 760px) {
    .v-container{
        min-width: auto;
        border: 2px solid var(--vt-c-widget-box-shadow)
    }
    .row.selected-preferences {
        display: block;
        .v-input {
            width: 100%;
        }
    }
    span.v-chip {
        float: right;
    }
    .optional-preferences {
        width: 100%;
    }
    .v-row.pl-10.pt-10.pb-10.pr-10 {
        margin: 0;
    }
    .v-input.v-input--horizontal.v-input--center-affix.v-input--density-default.v-locale--is-ltr.v-switch {
        display: block;
        float: right;
        width: 40px;
        margin-top: -10px;
    }
    .radio-option {
        display: none;
    }

}

</style>

export const enum ReservationPreferences {
    TableSeat = "Table Seat",
    NearLuggae = "Near Luggage Rack",
    PowerSocket = "Near Power Socket",
    NearToilet = "Near Toilet",
}

export const enum SeatDirection {
    NoPreference = "Any",
    BackwardFacing = "Backward",
    ForwardFacing = "Forward",
}

export const enum SeatPosition {
    NoPreference = "Any",
    Aisle = "Aisle",
    Window = "Window",
}

export const enum CoachType {
    NoPreference = "Any",
    Quiet = "Quiet"
}

export const enum FareDetailsFlag {
    RequiresSeatReservation = "RequiresSeatReservation"
}

export const enum ReservationRequestModel {
    TableSeat = "tableSeat",
    NearLuggae = "nearLuggageRack",
    PowerSocket = "powerSocket",
    NearToilet = "nearToilet",
}

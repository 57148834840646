export function getDateDiff(startDate: string, endDate: string, includeSeconds: boolean = false): string {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let seconds = Math.floor((end.valueOf() - (start.valueOf())) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    hours -= (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    let result = "";

    if (days > 0) {
        result += `${days}d `;
    }

    if (hours > 0) {
        result += `${hours}h `;
    }

    if (minutes > 0) {
        result += `${minutes}m `;
    }

    if (includeSeconds && seconds > 0) {
        result += `${seconds}s `;
    }

    return result;
}

export function toLongDate(dateString: string) {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat("en-GB", {
        timeZone: "Europe/London",
        year: "numeric",
        month: "long",
        day: "2-digit",
        weekday: "long",
    }).format(date).replace(/, /g, " ");
}

export function toShortMonthDate(dateString: string) {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat("en-GB", {
        timeZone: "Europe/London",
        day: "numeric",
        month: "short",
        year: "numeric",
    }).format(date).replace(/, /g, " ");
}

export function toShortDate(dateString: string) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
        timeZone: "Europe/London",
        year: "numeric",
        month: "numeric",
        day: "numeric",
    }).format(date);
}

export function formatTime(dateString: string): string {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
        timeZone: "Europe/London",
        hour: "numeric",
        minute: "numeric",
    }).format(date);
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export function addTime(date: string, value: number, unit: string): Date {
// TODO: Update this to use moment? Use the passed in unit (h,m,s) to add the time on
    return new Date(new Date(date).getTime() + value * 60000);
}

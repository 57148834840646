<script setup lang="ts">
import { toRefs, onMounted, ref } from "vue";
import type { Ref } from "vue";
import type { FulfilmentRequestResponse } from "@/models/checkout/FulfilmentRequestModel";
import { useBookingData } from "@/stores/booking-storage";

const props = withDefaults(defineProps<{
    email: string,
    bookingData: FulfilmentRequestResponse,
}>(), {
    email: "",
});

const { email } = toRefs(props);
const { bookingData } = toRefs(props);
const reference: Ref<string | undefined> = ref("");
const pdfArray: Ref<Array<string>> = ref([]);
const bookingStore = useBookingData();

onMounted(() => {
    bookingStore.clearBookingData();
    reference.value = bookingData.value.todCollectionReference;
    pdfArray.value = bookingData.value.trips.flatMap((trip) => trip.fares)
        .flatMap((fare) => fare.tickets).flatMap((ticket) => ticket.etickets)
        .flatMap((eticket) => eticket!.pdfData);
});

function downloadPdf() {
    pdfArray.value.forEach((pdf, i) => {
        const pdfData = pdf;
        const blob = new Blob([Uint8Array.from(atob(pdfData), (c) => c.charCodeAt(0))], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `ticket-${i + 1}.pdf`;
        a.click();
    });
}

function formatLabel() {
    return pdfArray.value.length > 1 ? "Download E-Tickets" : "Download E-Ticket";
}
</script>

<template>
    <div class="text-center pt-15">
        <font-awesome-icon icon="fa-circle-check" class="fa-lg orange"></font-awesome-icon>
        <h2 class="pt-5">Your booking is complete</h2>
        <div>Check your inbox at {{ email }}</div>
        <div>for an email containing confirmation of your booking</div>
        <div>If it's not there within 15 minutes, and you've checked your <i>Spam</i>, please contact <a href= "mailto:info@mytrainticket.co.uk">customer services</a></div>
        <div class="pt-5" v-if="reference !== ''">Your ticket collection reference is: {{ reference }}</div>
        <v-btn v-if="pdfArray.length !== 0" class="primary-button mt-10" @click="downloadPdf()">
            {{ formatLabel() }}
        </v-btn>
    </div>
</template>

<style scoped lang="scss">
svg.svg-inline--fa.fa-circle-check.fa-lg.orange {
    scale: 2;
}
.v-container {
    line-height: 2;
}
.v-btn {
    box-shadow: none;
}
</style>

import Axios from "axios";
import axios, {
    AxiosError,
    AxiosHeaders,
    type AxiosInstance,
    type AxiosRequestConfig,
    type AxiosResponse,
    HttpStatusCode,
} from "axios";
import type { ProblemDetail } from "@/api/ProblemDetail";
import { fetchAuthSession } from "aws-amplify/auth";

export interface IApiClient {
    get<TResponse>(path: string, config?: AxiosRequestConfig): Promise<TResponse>;

    post<TRequest, TResponse>(path: string, object: TRequest, config?: AxiosRequestConfig): Promise<TResponse>;

    put<TRequest, TResponse>(path: string, object: TRequest): Promise<TResponse>;

    delete<TResponse>(path: string): Promise<TResponse>;
}

const headers: Readonly<Record<string, string | boolean>> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Credentials": true,
    "X-Requested-With": "XMLHttpRequest",
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `access_token` from the localStorage that we set when we authenticate
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const injectToken = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    try {
        const session = await fetchAuthSession();
        if (session.tokens !== undefined && config.headers) {
            const token = session.tokens!.idToken!;
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    } catch (error: any) {
        throw new Error(error);
    }
};

function getHeaderValue(axiosResponse: AxiosResponse, header: string): string {
    const responseHeaders = axiosResponse.headers;
    if (responseHeaders instanceof AxiosHeaders && responseHeaders.has(header)) {
        return responseHeaders[header];
    }

    return "";
}

function handleErrorResponse(axiosResponse: AxiosResponse<any, any>): ProblemDetail {
    let pd: ProblemDetail = {
        detail: axiosResponse.statusText,
        status: axiosResponse.status,
    };

    // if it has an "errors" array, it's probably from GraphQL
    if (axiosResponse.data.errors) {
        pd.title = "Something went wrong";
        pd.detail = axiosResponse.data.errors.map((e: any) => e.message).join(" ");
    }

    const contentType = getHeaderValue(axiosResponse, "content-type");
    if (contentType.includes("application/problem+json")) {
        pd = axiosResponse.data as ProblemDetail;
    } else if (!pd.detail) {
        if (axiosResponse.status === HttpStatusCode.NotFound) {
            pd.title = "Not Found";
            pd.detail = axiosResponse.request.responseURL;
        }
    }

    return pd;
}

function handleAxiosError(axiosError: AxiosError): ProblemDetail {
    if (axiosError.response) {
        return handleErrorResponse(axiosError.response);
    }

    return {
        detail: axiosError.message,
        status: HttpStatusCode.InternalServerError,
    };
}

export default class ApiClient implements IApiClient {
    private client: AxiosInstance;

    protected createAxiosClient(): AxiosInstance {
        const instance = Axios.create({
            responseType: "json" as const,
            headers,
            timeout: 10 * 1000,
        });

        // @ts-ignore
        // https://github.com/axios/axios/issues/5573
        instance.interceptors.request.use(injectToken, (error) => Promise.reject(error));

        return instance;
    }

    constructor() {
        this.client = this.createAxiosClient();
    }

    async get<TResponse>(path: string, config?: AxiosRequestConfig): Promise<TResponse> {
        try {
            const response = config ? await this.client.get<TResponse>(path, config) : await this.client.get<TResponse>(path);
            if (response.status === HttpStatusCode.Ok) {
                return response.data;
            }

            return Promise.reject(handleErrorResponse(response));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return Promise.reject(handleAxiosError(error));
            }
            return Promise.reject(error);
        }
    }

    async post<TRequest, TResponse>(
        path: string,
        payload: TRequest,
        config?: AxiosRequestConfig,
    ): Promise<TResponse> {
        try {
            const response = config
                ? await this.client.post<TResponse>(path, payload, config)
                : await this.client.post<TResponse>(path, payload);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return Promise.reject(handleAxiosError(error));
            }
            console.error(error);
            throw error;
        }
    }

    async put<TRequest, TResponse>(
        path: string,
        payload: TRequest,
    ): Promise<TResponse> {
        try {
            const response = await this.client.put<TResponse>(path, payload);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return Promise.reject(handleAxiosError(error));
            }
            console.error(error);
            throw error;
        }
    }

    async delete<TResponse>(
        path: string,
    ): Promise<TResponse> {
        try {
            const response = await this.client.delete<TResponse>(path);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return Promise.reject(handleAxiosError(error));
            }
            console.error(error);
            throw error;
        }
    }
}

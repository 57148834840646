<script setup lang="ts">
import {
    toRefs, ref, onMounted, watch,
} from "vue";
import { DeliveryMethods } from "@/models/basket/DeliveryMethods";
import type { Ref } from "vue";

const props = defineProps<{
    deliveryMethods: any,
    collectionFromOrigin: Boolean,
    firstOriginStation: string,
}>();
const emit = defineEmits(["selectedDelivery"]);

const { deliveryMethods } = toRefs(props);
const { collectionFromOrigin } = toRefs(props);
const { firstOriginStation } = toRefs(props);
const selectedDelivery: Ref<DeliveryMethods | null> = ref(null);
const tod: string = "Tod";

function setSelection() {
    selectedDelivery.value = deliveryMethods.value.includes(DeliveryMethods.ETicket) ? DeliveryMethods.ETicket : DeliveryMethods.Tod;
}

function formatMethod(method: string) {
    if (deliveryMethods.value.length === 1) {
        setSelection();
    }
    switch (method.toLowerCase()) {
        case "eticket":
            return DeliveryMethods.ETicket;
        case "tod":
            return DeliveryMethods.Tod;
        default:
            return `Unknown delivery method ${method}`;
    }
}

onMounted(() => {
    selectedDelivery.value = DeliveryMethods.ETicket;
});

watch(selectedDelivery, () => {
    if (selectedDelivery.value === DeliveryMethods.Tod) {
        emit("selectedDelivery", "tod");
    } else {
        emit("selectedDelivery", selectedDelivery.value);
    }
});
</script>

<template>
    <v-row class="mt-10 pt-5 pl-5 pb-5 pr-5 delivery-method">
        <div class="container">
            <h3 class="font-weight-bold">Delivery Method</h3>
            <div class="pb-5">Available delivery methods for basket:
                <span class="ml-3 pl-3 pr-3 pt-1 pb-1" v-for="(method, i) in deliveryMethods" :key="i">
                    {{ formatMethod(method) }}
                </span>
            </div>
            <v-radio-group v-model="selectedDelivery">
                <div v-if="deliveryMethods.includes(tod)" class="tod-container delivery-option">
                    <v-row no-gutters>
                        <v-col>
                            <v-radio :value="DeliveryMethods.Tod" class="pt-5"></v-radio>
                            <font-awesome-icon icon="fa-computer-classic" class="fa-lg computer pt-6 ml-5"></font-awesome-icon>
                        </v-col>
                        <v-col>
                            <v-sheet class="pa-2 ma-2">
                                <div class="description">
                                    <h3 class="font-weight-bold">Collect from any station with a ticket machine</h3>
                                    <div>You'll need your
                                        <a class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Must be the same card as the one used for payment">debit/credit card</a> and your collection reference.</div>
                                    <div>Tickets will be ready for collection shortly after booking.</div>
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row no-gutters class="tod-summary">
                        <v-col>
                            <v-sheet class="pa-2 ma-2">
                                You can collect your ticket from <a href="https://www.nationalrail.co.uk/find-a-station/" >over 1,700 stations</a>, but you must collect your tickets before you start your journey.
                                <div v-if="!collectionFromOrigin" class="tod-warning mt-4 pa-3">
                                    <b>Important:</b> you can't collect tickets at {{ firstOriginStation }}. You'll need to pick up your tickets from an alternative station before travel.
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="deliveryMethods.includes(DeliveryMethods.ETicket)" class="tod-container delivery-option mt-5" no-gutters>
                    <v-row no-gutters>
                        <v-col>
                            <v-radio :value="DeliveryMethods.ETicket" class="pt-5"></v-radio>
                            <font-awesome-icon icon="fa-qrcode" class="fa-lg computer pt-6 ml-5"></font-awesome-icon>
                        </v-col>
                        <v-col>
                            <v-sheet class="pa-2 ma-2">
                                <div class="description">
                                    <h3 class="font-weight-bold">Use e-tickets to avoid the queues</h3>
                                    <div>We’ll email your tickets immediately.</div>
                                </div>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row no-gutters>
                        <p class="mb-5">Breeze through the station and show or scan your etickets at the gate. You <b>choose</b> how to use them…</p>
                    </v-row>
                    <v-row no-gutters class="tod-summary">
                        <div class="summary mt-5">
                            <div class="ticket-option phone">
                                <font-awesome-icon icon="fa-envelope-open-text" class="fa-lg"></font-awesome-icon>
                                <div>
                                    <h3 class="font-weight-bold">Show on your phone</h3>
                                    Open the PDF attached to the confirmation<br>
                                    email, or send it to another traveller.
                                </div>
                            </div>
                            <div class="ticket-option print">
                                <font-awesome-icon icon="fa-print" class="fa-lg"></font-awesome-icon>
                                <div>
                                    <h3 class="font-weight-bold">Print a copy</h3>
                                    If you’d like the reassurance of having a backup.
                                </div>
                            </div>
                        </div>
                    </v-row>
                </div>
            </v-radio-group>
        </div>
    </v-row>
</template>

<style scoped lang="scss">
.ticket-option {
    text-align: center;
    &.print {
        margin-left: .93rem;
    }
}
.v-selection-control.v-selection-control--density-default.pt-5.v-radio.pt-5 {
    float: left;
}
.delivery-option {
    border: 3px solid var(--vt-c-orange);
    font-size: .9375rem;
    border-radius: 5px;
    padding: 1.25rem;
    .v-row {
        .v-col {
            flex-shrink: 0;
            flex-basis: content;
            width:auto;
            flex-grow: 0;
            padding-right: 15px;
        }
    }
}
.container {
    width: 100%;
    font-size: .9375rem;
}
.tod-warning {
    background-color: var(--vt-c-red);
    color: var(--vt-c-white);
}
.tod-summary {
    justify-content: center;
}
.summary {
    display: inline-flex;
}
.description {
    line-height: 1.75;
    a {
        color: var(--vt-c-black);
        text-decoration: underline;
        text-decoration-style: dotted;
    }
    p {
        margin-left: -10%;
    }
}
.v-col:has(svg.computer) .computer {
    font-size: 2rem;
}
.ticket-option svg {
    font-size: 1.875rem;
}
.v-selection-control--dirty.v-selection-control--density-default.pt-5.v-radio.pt-5 {
    color: var(--vt-c-white);
}
.v-selection-control.v-radio {
    color: var(--vt-c-orange);
}
:deep(.v-selection-control--dirty .v-selection-control__wrapper) {
    background-color: var(--vt-c-orange);
}
hr.v-divider.v-theme--light {
    margin-top: 30px;
    border-bottom: solid 2px var(--vt-c-divider-dark-2);
    margin-bottom: 30px;
}
span {
    background-color: var(--vt-c-widget-box-shadow);
}

:deep(.v-selection-control__wrapper) {
    border: 2px solid var(--vt-c-orange);
    border-radius: 5px;
}
@media screen and (max-width: 768px) {
    .summary {
        display: inline-block;
    }
    hr.v-divider.v-theme--light {
        display: none;
    }
    .delivery-option {
        width: auto;
        padding: 0px 10px;
        .v-row .v-col {
            flex: auto;
            flex-basis: auto;
        }
    }
    .v-col {
        flex-basis: auto;
        .description {
            margin-left: 0px;
            font-size: .875rem;
            line-height: 1.5;
            p {
                margin-left: 0px;
            }
        }
        svg.computer {
            font-size: 1.8625rem
        }
    }
    .ticket-option.print {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
    .v-row.v-row--no-gutters.tod-summary {
        text-align: center;
    }
    .v-selection-control.v-radio {
        color: var(--vt-c-orange);
        float: left;
    }
}
</style>

export const enum TransportModes {
    Train = "Train",
    Bus = "Bus",
    Tube = "Tube",
    Walk = "Walk",
    Ship = "Ship",
    Transfer = "Transfer",
    Metro = "Metro",
    Tram = "Tram",
}

import type { StripeSessionModel } from "@/models/checkout/StripeSessionModel";
import type { IApiClient } from "./ApiClient";

export interface IStripeClient {
    getStripeParams(bookingId: string): Promise<StripeSessionModel>;
}

const getStripeSessionEndpoint: string = "/gql/get-stripe-session-params/";

export class StripeClient implements IStripeClient {
    stripeApiClient: IApiClient;

    constructor(retailApiClient: IApiClient) {
        this.stripeApiClient = retailApiClient;
    }

    async getStripeParams(bookingId: string): Promise<StripeSessionModel> {
        return this.stripeApiClient.get(`${getStripeSessionEndpoint}${bookingId}`);
    }
}

<script setup lang="ts">

import { type Ref, ref, watch } from "vue";
import { useAlertStore } from "@/stores/alert-store";
import type { Alert } from "@/services/AlertService";

const props = defineProps<{
    id: string
}>();

const store = useAlertStore();

const displayItems: Ref<Array<Alert>> = ref([]);

watch(store, () => {
    if (store.alerts.value.length > 0) {
        displayItems.value = store.alerts.value.filter((x) => x.componentId === props.id || x.componentId === undefined);
    } else {
        displayItems.value = [];
    }
});

function closeAlert(idx: any): void {
    store.removeAlert(idx);
}

</script>

<template>

    <div :class="{'close-alert': displayItems.length === 0}" :id="`${id}-alert-container`">

        <div class="mb-2" v-for="(alert, index) in displayItems" :key="index">

            <v-alert
                @click:close="closeAlert(alert.key)"
                border="start"
                closable
                close-label="Close Alert"
                density="compact"
                :type="alert.type"
                :title="alert.title!"
                variant="tonal">
                {{ alert.message }}
                <div class="mb-2" v-for="(item, key) in alert.items" :key="key">
                    <span>{{ key }}</span>

                    <ul class="ps-5" v-for="message in item" :key="message">
                        <li>{{ message }}</li>
                    </ul>
                </div>

            </v-alert>

        </div>

    </div>

</template>
<style scoped>
:deep(.v-alert__prepend) {
    display: none;
}
:deep(.v-alert__border) {
    display: none;
}
:deep(.v-alert__content) {
    text-align: center;
}
:deep(.v-alert__content) {
    text-align: center;
    color: var(--vt-c-orange)
}
:deep(.v-alert-title) {
    font-size: 1rem;
    line-height: 1;
    padding-bottom: 15px;
    display: block;
    padding-top: 10px;
}
:deep(button.v-btn) {
    background-color: var(--vt-c-orange)
}
:deep(span.v-btn__content) {
    color: var(--vt-c-white)
}
:deep(.v-alert) {
    border-radius: 20px;
}
:deep(.close-alert) {
    display: none;
}
div#default-alert-container {
    display: inline-flex;
    width: 100%;
    justify-content: center;
}
@media only screen and (max-width: 760px) {
    :deep(.v-alert__close) {
        padding-top: 6px;
    }
}

</style>

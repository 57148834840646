import type { ValidationError } from "./ValidationError";

export function validateOriginDestination(values: any): ValidationError[] {
    const errorMessages: ValidationError[] = [];
    const originStation = values.originStation.place;
    const destinationStation = values.destinationStation.place;

    if (originStation.placeId === destinationStation.placeId) {
        errorMessages.push({
            Property: "destinationStation",
            Messages: ["Origin and destination stations cannot be the same."],
        });
    }

    return errorMessages;
}

<script setup lang="ts">
import {
    ref, onMounted, inject, watch,
} from "vue";
import type { Ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import type { AuthTokens } from "aws-amplify/auth";
import { retailInstanceTypeSymbol } from "@/models/Symbols";
import type { RetailInstanceTypeService } from "@/services/RetailInstanceTypeService";
import { useBookingData } from "@/stores/booking-storage";

const route = useRoute();
const $router = useRouter();
const isHidden: Ref<boolean> = ref(false);
const isLoggedIn: Ref<boolean> = ref(false);
const userEmail: Ref<string> = ref("");
const basketCount: Ref<number> = ref(0);
const bookingStore = useBookingData();

function updateBasketCount() {
    basketCount.value = bookingStore.bookingData?.trips.length ? bookingStore.bookingData!.trips.length : 0;
}

onMounted(async () => {
    await $router.isReady();
    isHidden.value = "hide-nav" in route.query && route.query["hide-nav"] === "true";
    const session = await fetchAuthSession();
    if (session.tokens !== undefined) { // if we're logged in
        isLoggedIn.value = true;
        const details = await getCurrentUser();
        userEmail.value = details.signInDetails?.loginId || "";
    }
    updateBasketCount();
});

watch(bookingStore, () => {
    updateBasketCount();
});

const logout = async () => {
    try {
        await signOut();
    } catch (error) {
        console.error(error);
    }
};

Hub.listen("auth", (ev) => {
    const { payload } = ev;
    switch (payload.event) {
        case "signedIn":
            isLoggedIn.value = true;
            // AWS Amplify's types leave some room for improvement
            userEmail.value = (payload.data! as unknown as AuthTokens)!.signInDetails!.loginId!;
            break;
        case "signedOut":
            isLoggedIn.value = false;
            break;
        default:
            break;
    }
});

const instanceTypeSvc = inject(retailInstanceTypeSymbol) as RetailInstanceTypeService;

</script>

<template>
    <nav v-if="!isHidden" class="navbar navbar-expand-md navbar-dark fixed-top has-shadow">
        <div class="navbar-brand">
            <a href="/" class="navbar-item">
                <img src="@/assets/logo.svg" width="185" height="28" alt="MyTrainTicket">
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
                aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <RouterLink class="nav-link" to="/">Home</RouterLink>
                </li>
                <li class="nav-item">
                    <RouterLink class="nav-link" :to="`/your-basket/`">Basket ({{basketCount}})</RouterLink>
                </li>
                <li class="nav-item" v-if="isLoggedIn">
                    <a class="nav-link" @click="logout">Logout ({{userEmail}})</a>
                </li>
                <li class="nav-item" v-else>
                    <RouterLink class="nav-link" to="/login">Login</RouterLink>
                </li>
                <li class="nav-item"> <!-- TODO: Remove me, just an example -->
                    {{instanceTypeSvc.isConsumer() ? "Consumer" : "Corp"}}
                </li>
            </ul>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
nav {
    padding-left: 40px;
    box-shadow: 0 2px 0 0 var(--vt-c-widget-box-shadow);
    padding-top: 15px;
    background-color: var(--vt-c-white);
    display: inline-block;
    width: 100%;
}

.navbar-brand {
    float: left;
}
.fixed-top .collapse.navbar-collapse {
    float: right;
    padding-right: 10rem;
}

.navbar-brand a:hover {
    background-color: var(--vt-c-white);
}

 .nav-link{
    color: var(--vt-c-orange);
    font-weight: normal;
    font-size: 1rem;
}

.nav-link:hover {
    background-color: var(--nav-link-hover);
}
ul.navbar-nav.mr-auto .nav-item {
    list-style: none;
    display: inline;
    padding-left: 20px;
}
@media only screen and (max-width: 760px) {
    nav.navbar {
        padding-left: 10px;
        padding-top: 10px;
    }
    .fixed-top .collapse.navbar-collapse {
        float: none;
        padding-right: 10px;
    }
}

</style>

import { loadStripe } from "@stripe/stripe-js";
import { ref } from "vue";
import type { Ref } from "vue";
import { defineStore } from "pinia";
import { StripeConstants } from "@/utilities/StripeConstants";

export const usePaymentStore = defineStore("payment", () => {
    const stripeReady: Ref<boolean> = ref(false);

    async function initialiseStripe() {
        const stripePromise = loadStripe(StripeConstants.PublishableKey);
        stripePromise.then(() => {
            stripeReady.value = true;
        });
    }

    return {
        stripeReady,
        initialiseStripe,
    };
});

import { JourneyType } from "@/models/JourneyType";
import type { ValidationError } from "@/validators/ValidationError";

export function validateOutboundReturnDate(values: any): ValidationError[] {
    const errorMessages: ValidationError[] = [];

    if (values.journeyType !== JourneyType.Return) {
        return errorMessages;
    }

    const { outboundDate } = values;
    const { returnDate } = values;

    if (returnDate < outboundDate) {
        if (outboundDate.getFullYear() === returnDate.getFullYear()
            && outboundDate.getMonth() === returnDate.getMonth()
            && outboundDate.getDate() === returnDate.getDate()) {
            errorMessages.push({
                Property: "returnHour",
                Messages: ["Return time must be the same or later in the day than the outbound time."],
            });
        } else {
            errorMessages.push({
                Property: "returnDate",
                Messages: ["Return date must be the same or later than the outbound date."],
            });
        }
    }

    return errorMessages;
}

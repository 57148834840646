<script setup lang="ts">
import {
    ref, watch, computed, onMounted,
} from "vue";
import { useRoute } from "vue-router";
import { JourneyTimeType } from "@/models/JourneyTimeType";
import { useField } from "vee-validate";
import Calendar from "primevue/calendar";
import { useJourneyStore } from "@/stores/journey-store";
import { dateStringFormatter } from "@/utilities/TimeFormatter";

const dateType = ref(true);
const outboundTime = ref("");
const returnTime = ref("");
const minDate = new Date();
const outboundDateField = useField<Date>("outboundDate", () => true);
const returnDateField = useField<Date>("returnDate", () => true);
const outboundDate = outboundDateField.value;
const returnDate = returnDateField.value;
const store = useJourneyStore();
let formattedTimeString = computed(dateStringFormatter(store.query.outboundDate));
const route = useRoute();
const outboundSelection = "outbound" in route.query;

watch(outboundDate, (newOutboundDate: Date) => {
    const currentStoredHour = store.query.outboundDate.getHours();
    const currentStoredMins = store.query.outboundDate.getMinutes();
    newOutboundDate.setHours(currentStoredHour);
    newOutboundDate.setMinutes(currentStoredMins);
    store.query.outboundDate = newOutboundDate;
});

watch(returnDate, (newReturnDate: Date) => {
    const currentStoredHour = store.query.returnDate.getHours();
    const currentStoredMins = store.query.returnDate.getMinutes();
    newReturnDate.setHours(currentStoredHour);
    newReturnDate.setMinutes(currentStoredMins);
    store.query.returnDate = newReturnDate;
});

watch(outboundTime, (newTimeSelection: any) => {
    const time = newTimeSelection.split(":");
    const date = new Date(store.query.outboundDate);
    date.setHours(Number(time[0]), Number(time[1]));
    store.query.outboundDate = date;
    formattedTimeString = computed(dateStringFormatter(store.query.outboundDate));
});

watch(returnTime, (newTimeSelection: any) => {
    const time = newTimeSelection.split(":");
    const date = new Date(store.query.returnDate);
    date.setHours(Number(time[0]), Number(time[1]));
    store.query.returnDate = date;
    formattedTimeString = computed(dateStringFormatter(store.query.returnDate));
});

function toggle(value: boolean) {
    store.query.outboundTimeType = (value ? JourneyTimeType.DepartingAt : JourneyTimeType.ArrivingBefore);
    return store.query.outboundTimeType;
}

function toToday() {
    const fifteenMinsInMs = 15 * 60 * 1000;
    dateType.value = true;
    const today = new Date(Math.ceil(new Date().getTime() / fifteenMinsInMs) * fifteenMinsInMs);
    outboundDate.value = today;
}

function setNow() {
    const start = new Date();
    const hour = start.getHours();
    const minutes = start.getMinutes();
    store.query.outboundDate.setHours(hour);
    store.query.outboundDate.setMinutes(minutes);
}

function toTomorrow() {
    dateType.value = false;
    const dateValue = new Date();
    dateValue.setDate(dateValue.getDate() + 1);
    outboundDate.value = dateValue;
}
onMounted(() => {
    outboundDate.value = store.query.outboundDate;
    returnDate.value = store.query.returnDate;
});

</script>

<template>
    <v-container>
        <RouterLink class="nav-link" to="/">
            <font-awesome-icon icon="fa-arrow-left"></font-awesome-icon>Search
        </RouterLink>
        <div v-if="outboundSelection">
            <div id="time-date-selection" class="ps-2 row outbound-search">
                <div class="label type pt-5 ml-10">Search Type</div>
                <div align="center" class="ui buttons big pt-1">
                    <v-btn
                        class="ui button toggle"
                        @click="toggle(true)"
                        :class="[store.query.outboundTimeType === JourneyTimeType.DepartingAt ? 'active' : '', 'depart-before']"
                        :value="JourneyTimeType.DepartingAt"
                        :v-model="store.query.outboundTimeType"
                    >Depart After</v-btn>
                    <v-btn
                        class="ui button toggle"
                        @click="toggle(false)"
                        :class="[store.query.outboundTimeType === JourneyTimeType.ArrivingBefore ? 'active' : '', 'arrive-after']"
                        :value="JourneyTimeType.ArrivingBefore"
                        v-model="store.query.outboundTimeType"
                    >Arriving Before</v-btn>
                </div>
            </div>
            <div id="mobile-outbound-date-container" class="ml-12 pt-7">
                <div class="label">Date and Time</div>
                <Calendar v-model="outboundDate" dateFormat="dd/mm/yy" :manualInput="false" showIcon :minDate="minDate"
                          appendTo="self" :inputClass="{ 'p-invalid': outboundDateField.errorMessage.value }" ></Calendar>
                <div class="shortcut-container">
                    <button
                        :class="[dateType ? 'active' : '', 'travel-day-shortcut today']"
                        @click="toToday">Today</button>
                    <button
                        :class="[!dateType ? 'active' : '', 'travel-day-shortcut tomorrow']"
                        @click="toTomorrow">Tomorrow</button>
                </div>
            </div>
            <div id="mobile-time-container">
                <v-text-field
                    type="time"
                    v-model="outboundTime"
                    class="ml-11 pt-10"
                    :value="formattedTimeString">
                    <template v-slot:append>
                        <button class="now-take-ii" @click="setNow()">Now</button>
                    </template>
                </v-text-field>
            </div>
        </div>
        <div v-else>
            <div id="return-time-date-selection" class="ps-2 row return-search">
                <div class="label type pt-5 ml-10">Search Type</div>
                <div align="center" class="ui buttons big pt-1">
                    <v-btn
                        class="ui button toggle"
                        @click="toggle(true)"
                        :class="[store.query.returnTimeType === JourneyTimeType.DepartingAt ? 'active' : '', 'depart-before']"
                        :value="JourneyTimeType.DepartingAt"
                        :v-model="store.query.returnTimeType"
                    >Depart After</v-btn>
                    <v-btn
                        class="ui button toggle"
                        @click="toggle(false)"
                        :class="[store.query.returnTimeType === JourneyTimeType.ArrivingBefore ? 'active' : '', 'arrive-after']"
                        :value="JourneyTimeType.ArrivingBefore"
                        v-model="store.query.returnTimeType"
                    >Arriving Before</v-btn>
                </div>
            </div>
            <div id="mobile-return-date-container" class="ml-12 pt-7">
                <div class="label">Date and Time</div>
                <Calendar v-model="returnDate" dateFormat="dd/mm/yy" :manualInput="false" showIcon :minDate="minDate"
                          appendTo="self" :inputClass="{ 'p-invalid': returnDateField.errorMessage.value }" ></Calendar>
                <div class="shortcut-container">
                    <button
                        :class="[dateType ? 'active' : '', 'travel-day-shortcut today']"
                        @click="toToday">Today</button>
                    <button
                        :class="[!dateType ? 'active' : '', 'travel-day-shortcut tomorrow']"
                        @click="toTomorrow">Tomorrow</button>
                </div>
            </div>
            <div id="mobile-time-container">
                <v-text-field
                    type="time"
                    v-model="returnTime"
                    class="ml-11 pt-10"
                    :value="formattedTimeString">
                    <template v-slot:append>
                        <button class="now-take-ii" @click="setNow()">Now</button>
                    </template>
                </v-text-field>
            </div>
        </div>

        <RouterLink class="nav-link" to="/">
            <button class="save mt-10">Save</button>
        </RouterLink>
    </v-container>
</template>

<style lang="scss" scoped>
@media only screen and (max-width: 760px) {
    button {
        text-transform: capitalize;
        height: 40px;
        &.active {
            background-color: var(--vt-c-orange);
            color: var(--vt-c-white);
        }
        &.v-btn.ui.button.toggle {
            box-shadow: none;
            border: 2px solid var(--vt-c-orange);
            line-height: 1;
        }
        &.v-btn.v-btn--variant-elevated.ui.button.toggle.arrive-after {
            border-left: none;
            border-radius: 0px 20px 20px 0px;
        }
        &.v-btn--variant-elevated.ui.button.toggle.depart-before {
            border-radius: 20px 0px 0px 20px;
            border-right: none;
        }
        &.travel-day-shortcut {
            width: 140px;
            &.tomorrow {
                float: right;
            }
        }

        &.travel-day-shortcut.today {
            border-radius: 0px 0px 0px 20px;
        }
        &.travel-day-shortcut.tomorrow {
            border-radius: 0px 0px 20px 0px;
            border-left: none;
        }
        &.travel-day-shortcut {
            border: 2px solid var(--vt-c-orange)
        }
        &.travel-day-shortcut {
            border: 2px solid var(--vt-c-orange);
            border-top: none;
        }
        &.save {
            margin-left: 50px;
            width: 271px;
            border-radius: 20px;
            border: 2px solid var(--vt-c-orange);
            background-color: var(--vt-c-orange);
            color: var(--vt-c-white)
        }
        &.save {
            background-color: var(--vt-c-orange);
            color: var(--vt-c-white)
        }
    }
    div#mobile-outbound-date-container,
    div#mobile-return-date-container {
        width: 280px;
    }
    div#mobile-time-container {
        display: inline-block;
    }
    .v-input.v-input--density-default.v-locale--is-ltr.v-text-field {
        width: 285px;
    }
    :deep(input.p-inputtext.p-component) {
        border-radius: 20px 0px 0px 0px;
    }
    :deep(button.p-button.p-component.p-button-icon-only.p-datepicker-trigger) {
        border-radius: 0px 20px 0px 0px;
        background-color: var(--vt-c-orange);
        border: 2px solid var(--vt-c-orange);
        border-left: none;
    }

    :deep(div#mobile-time-container .v-input .v-input__append) {
        background-color: var(--vt-c-orange);
        width: 60px;
        justify-content: center;
        margin-inline-start: 0px;
        border-radius: 0px 20px 20px 0px;
        color: var(--vt-c-white);
    }
    :deep(input.p-inputtext.p-component) {
        border-radius: 20px 0px 0px 0px;
        border: 2px solid var(--vt-c-orange);
    }
    :deep(.v-field.v-field--active.v-field--center-affix.v-theme--light) {
        border-radius: 20px 0px 0px 20px;
        background-color: var(--vt-c-white);
        border: 2px solid var(--vt-c-orange);
        .v-field__outline {
            display:none;
        }
    }
    .v-field__outline {
        display: none;
    }
    :deep(.v-field__outline) {
        display: none;
    }
    :deep(.p-calendar .p-inputtext:enabled:hover),
    :deep(.p-calendar .p-inputtext:enabled:focus) {
        border-color: var(--vt-c-orange);
        outline: none;
    }
    :deep(.v-input input) {
        padding-left: 45px;
    }
}
</style>

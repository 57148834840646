<template>
    <div class="row">
        <div class="col-10">
            <div class="message w-100 p-2">
                <div class="message-header py-1 align-items-center justify-content-between">
                    <p class="my-0 lh-3 px-2"><b>Please correct the following problems:</b>
                        <button class="delete" @click="$emit('closeSummary')">X</button>
                    </p>
                </div>
                <div class="message-body p-3">
                    <ul class="m-0 pl-1 py-0">
                        <li v-for="(error, i) in props.errors" :key="`err${i}`">
                            {{ error }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.message .message-header {
    background-color: var(--vt-c-orange);
    color: var(--vt-c-white);
    height: 50px;
    padding: 10px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    b {
        line-height: 2.75;
    }
}

.message .message-body {
    border-color: var(--vt-c-orange);
    background-color: var(--vt-c-pink);
    color: var(--vt-c-orange);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.message {
    width: max-content;
}
ul {
    list-style: none;
    text-align: center;
}
button.delete {
    background-color: var(--vt-c-orange);
    border: none;
    border-radius: 100%;
    cursor: pointer;
    font-size: 10;
    height: 25px;
    outline: none;
    width: 25px;
    color: var(--vt-c-white);
    font-weight: 800;
    float: right;
    left: 15px;
}
@media only screen and (max-width: 760px) {
    button.delete {
    color: var(--vt-c-white);
    background-color: var(--vt-c-orange);
    height: 20px;
    width: 20px;
    line-height: 1;
    }
    .message .message-header {
        background-color: var(--vt-c-white);
    }
    .message .message-body {
        font-size: .875rem;
        padding: 20px;

        ul {
            text-align: left;
            list-style: disc;
        }
    }
    .message-header p {
        color: var(--vt-c-orange);
        display: flex;
    }
    .message .message-header {
        height: 55px;
        font-size: .9375rem;
        text-align: left;
    }
}
</style>
<script lang="ts" setup>
const props = defineProps<{
    errors: Partial<Record<never, string>>
}>();
</script>

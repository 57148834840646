export class RetailInstanceTypeService {
    private readonly instanceType: string;

    constructor(instanceType: string) {
        this.instanceType = instanceType;
    }

    isCorporate(): boolean {
        return this.instanceType === "B2B";
    }

    isConsumer(): boolean {
        return this.instanceType === "B2C";
    }
}

import type { IBookingOverviewResponse } from "@/models/graphql/BookingOverviewResponse";
import type { IPriceBreakdown } from "@/models/graphql/PriceBreakdownResponse";
import type { IApiClient } from "./ApiClient";

export interface IGQLBookingClient {
    addGQLBooking(bookingId: string | null, fareKeys: string[], outkey: string, inKey: string, searchId: string): Promise<IBookingOverviewResponse>;
    makeGQLReservation(bookingId: string, tripId: string, seatFacingDirection: string, seatPosition: string, coachType: string, features: string[]): Promise<string>;
    getGQLBookingOverview(bookingId: string): Promise<IBookingOverviewResponse>;
    emptyGQLBasket(bookingId: string): Promise<string>;
    removeGQLTrip(bookingId: string, tripId: string): Promise<string>;
    gqlPriceBreakdown(searchId: string, outboundJourneyKey: string, bookingId: string|null, fareKeys:string[], inboundJourneyKey?:string): Promise<IPriceBreakdown>;
}

const gqlBookingEndpoint: string = "/gql/add-trip-to-booking";
const gqlReservationsEndpoint: string = "/gql/make-reservations";
const gqlBookingOveriew: string = "/gql/get-booking-overview/";
const gqlDeleteBooking: string = "/gql/delete-booking";
const gqlDeleteTrip: string = "/gql/delete-trip-from-booking";
const gqlComputePrice: string = "gql/compute-pricing-breakdown";

export class GQLBookingClient implements IGQLBookingClient {
    gqlBookingClient: IApiClient;

    constructor(retailApiClient: IApiClient) {
        this.gqlBookingClient = retailApiClient;
    }

    async addGQLBooking(bookingId: string|null, fareKeys: string[], outkey: string, inKey: string, searchId: string): Promise<IBookingOverviewResponse> {
        const params = {
            bookingId,
            fareKeys,
            outkey,
            inKey,
            searchId,
        };
        return this.gqlBookingClient.post(gqlBookingEndpoint, params);
    }

    async makeGQLReservation(bookingId: string, tripId: string, seatFacingDirection: string, seatPosition: string, coachType: string, features: string[]): Promise<string> {
        const params = {
            bookingId,
            tripId,
            seatFacingDirection,
            seatPosition,
            coachType,
            features,
        };
        return this.gqlBookingClient.post(gqlReservationsEndpoint, params);
    }

    async emptyGQLBasket(bookingId: string): Promise<string> {
        const params = {
            bookingId,
        };
        return this.gqlBookingClient.post(gqlDeleteBooking, params);
    }

    async removeGQLTrip(bookingId: string, tripId: string): Promise<string> {
        const params = {
            bookingId,
            tripId,
        };
        return this.gqlBookingClient.post(gqlDeleteTrip, params);
    }

    async getGQLBookingOverview(bookingId: string): Promise<IBookingOverviewResponse> {
        return this.gqlBookingClient.get(`${gqlBookingOveriew}${bookingId}`);
    }

    async gqlPriceBreakdown(searchId: string, outboundJourneyKey: string, bookingId: string|null, fareKeys: string[], inboundJourneyKey?: string): Promise<IPriceBreakdown> {
        const params = {
            searchId,
            outboundJourneyKey,
            inboundJourneyKey,
            bookingId,
            fareKeys,
        };
        return this.gqlBookingClient.post(gqlComputePrice, params);
    }
}

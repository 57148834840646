import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { JourneyType } from "@/models/JourneyType";
import { JourneyTimeType } from "@/models/JourneyTimeType";
import { ViaAvoidType } from "@/models/ViaAvoidType";
import type { JourneyQuery } from "@/models/JourneyQuery";
import type { GetJourneysRequest } from "@/models/remote/GetJourneysRequest";
import { retailService } from "@/services/RetailService";

export const useJourneyStore = defineStore("journey", () => {
    // Initialise the date to now, but round up to the nearest 15 minute interval
    const interval = 15 * 60 * 1000;
    const today = new Date(Math.ceil(new Date().getTime() / interval) * interval);
    const tomorrow = new Date(Math.ceil(new Date().getTime() / interval) * interval);
    tomorrow.setDate(today.getDate() + 1);

    const query: JourneyQuery = {
        adults: ref(1),
        children: ref(0),
        originStation: ref(undefined),
        destinationStation: ref(undefined),
        outboundDate: ref(today),
        outboundTimeType: ref(JourneyTimeType.DepartingAt),
        returnDate: ref(tomorrow),
        returnTimeType: ref(JourneyTimeType.DepartingAt),
        railCard: ref(undefined),
        railCardQuantity: ref(1),
        journeyType: ref(JourneyType.OneWay),
        viaAvoidStation: ref(undefined),
        viaAvoid: ref(ViaAvoidType.Via),
    };

    async function updateQueryFromRequest(request: GetJourneysRequest) {
        query.journeyType.value = request.journeyType;

        try {
            query.originStation.value = await retailService.getPlaceItem(request.from);
            query.destinationStation.value = await retailService.getPlaceItem(request.to);
        } catch (error) {
            console.error(error);
        }

        query.adults.value = parseInt(request.adults.toString(), 10);
        query.children.value = parseInt(request.children.toString(), 10);

        if (request.railcard) {
            query.railCardQuantity.value = request.railcardQuantity;

            try {
                const railcards = await retailService.getRailcards();
                const { railCard } = query;
                railCard.value = railcards.find((x) => x.code === request.railcard);
            } catch (error) {
                console.error(error);
            }
        }

        query.outboundDate.value = new Date(request.outboundDate);
        query.outboundTimeType.value = request.outboundTimeType;

        if (request.returnDate) {
            query.returnDate.value = new Date(request.returnDate);
        }

        if (request.returnTimeType) {
            query.returnTimeType.value = request.returnTimeType;
        }

        if (request.viaAvoid) {
            query.viaAvoid.value = request.viaAvoid;
        }

        if (request.viaAvoidStation) {
            try {
                query.viaAvoidStation.value = await retailService.getPlaceItem(request.viaAvoidStation);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const journeyQuery = computed(() => query);

    const hasRailCard = computed(() => query.railCard.value?.code !== undefined);

    const hasViaAvoidStation = computed(() => query.viaAvoidStation.value?.place.placeId !== undefined);

    return {
        query,
        journeyQuery,
        hasRailCard,
        hasViaAvoidStation,
        updateQueryFromRequest,
    };
});

import type { ValidationError } from "@/validators/ValidationError";
import { retailService } from "@/services/RetailService";

export const validateRailcardAndPassenger = (async (values: any): Promise<ValidationError[]> => {
    const errorMessages: ValidationError[] = [];
    let isValid = true;
    const totalPassengers: number = values.adults + values.children;
    const { numberOfRailcards } = values;

    if (totalPassengers <= 0) {
        isValid = false;
        errorMessages.push({
            Property: "adults",
            Messages: ["Minimum 1 passenger for a booking."],
        });
    } else if (totalPassengers > 9) {
        isValid = false;
        errorMessages.push({
            Property: "adults",
            Messages: ["Maximum 9 passengers for a booking."],
        });
    }

    const hasSelectedRailcard = values.railCard?.code !== undefined;

    if (isValid && hasSelectedRailcard) {
        const { maxAdults } = values.railCard;
        const { minAdults } = values.railCard;
        const { maxChildren } = values.railCard;
        const { minChildren } = values.railCard;

        if (values.adults > maxAdults * numberOfRailcards) {
            isValid = false;
            errorMessages.push({
                Property: "adults",
                Messages: ["Too many adults for the selected railcard. Please add non-railcard holders as separate journeys into your basket so you can pay for them together."],
            });
        }

        if (values.children > maxChildren * numberOfRailcards) {
            isValid = false;
            errorMessages.push({
                Property: "children",
                Messages: ["Too many children for the selected railcard. Please add non-railcard holders as separate journeys into your basket so you can pay for them together."],
            });
        }
        if (values.adults < minAdults * numberOfRailcards) {
            isValid = false;
            errorMessages.push({
                Property: "adults",
                Messages: ["Not enough adults for the selected railcard."],
            });
        }
        if (values.adults < minChildren * numberOfRailcards) {
            isValid = false;
            errorMessages.push({
                Property: "children",
                Messages: ["Not enough children for the selected railcard."],
            });
        }
    }

    if (isValid && hasSelectedRailcard) {
        const apiValidationMessage = "Your railcard is not valid for this combination of adults and children.  Please add non-railcard holders as separate journeys into your basket so you can pay for them together.";

        try {
            const validateRailcardResponse = await retailService
                .validateRailCard(values.railCard!.code, values.adults, values.children);

            if (validateRailcardResponse.railcardValid !== numberOfRailcards) {
                errorMessages.push({
                    Property: "railCard",
                    Messages: [apiValidationMessage],
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    return errorMessages;
});

<template>
    <div class="validity">
        <p v-if="furtherTicketDetails.validOutDays || furtherTicketDetails.validOutMonths">
            Travel out within {{ formatValidity(furtherTicketDetails.validOutDays, furtherTicketDetails.validOutMonths) }}.
        </p>
        <p v-if="furtherTicketDetails.validInDays || furtherTicketDetails.validInMonths">
            Travel back within {{ formatValidity(furtherTicketDetails.validInDays, furtherTicketDetails.validInMonths) }}.
        </p>
    </div>
</template>

<script setup lang="ts">
import { pluralise } from "@/utilities/SimplePluraliser";
import type { TicketDetailsByKey } from "@/models/JourneySelectorViewModel";

defineProps<{
  furtherTicketDetails: TicketDetailsByKey;
}>();

function formatValidity(days: number, months: number): string {
    let output = "";
    if (months > 0) {
        output += `${months} ${pluralise("month", months)}`;
        if (days > 0) {
            output += ` and ${days} ${pluralise("day", days)}`;
        }
    } else if (days > 0) {
        output += `${days} ${pluralise("day", days)}`;
    }
    return output;
}
</script>

<script setup lang="ts">
import { onMounted } from "vue";
import { usePaymentStore } from "@/stores/payment";
import AlertComponent from "@/components/Layout/AlertComponent.vue";
import HeaderBar from "./components/Layout/HeaderBar.vue";

const paymentStore = usePaymentStore();

onMounted(() => {
    paymentStore.initialiseStripe();
});
</script>

<template>
    <HeaderBar></HeaderBar>
    <div class="content ">
        <AlertComponent id="default" ></AlertComponent>
    </div>

    <RouterView ></RouterView>

<!--    <FooterBar />-->
</template>

<style lang="scss" scoped>
.content {
    height: fit-content;
}
</style>

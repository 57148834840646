<script setup lang="ts">
import JourneySelector from "@/components/1-JourneyPlanner/JourneySelector.vue";
import { onMounted, ref, toRefs } from "vue";
import type { Ref } from "vue";
import { useJourneyStore } from "@/stores/journey-store";
import type { GetJourneysRequest } from "@/models/remote/GetJourneysRequest";
import TicketSummary from "@/components/3-Basket/YourSelection.vue";
import type { JourneySelectorViewModel, SelectedFare } from "@/models/JourneySelectorViewModel";

const props = defineProps<{
  request: GetJourneysRequest
}>();

const { request } = toRefs(props);
const journeyQuery: GetJourneysRequest = request.value;
const searchResults: Ref<JourneySelectorViewModel | null> = ref(null);
const selectedOutbound: Ref<SelectedFare | null> = ref(null);
const selectedReturn: Ref<SelectedFare | null> = ref(null);
const store = useJourneyStore();
const journeySelector = ref<InstanceType<typeof JourneySelector>|null>(null);
const td: Ref = ref(null);
const requestToString = (getJourneysReq: GetJourneysRequest) => JSON.stringify(getJourneysReq);

onMounted(() => {
    store.updateQueryFromRequest(journeyQuery).then(() => {
        if (journeySelector.value) {
            journeySelector.value.syncStoreAndPinia();
        }
    }).catch((error) => {
        console.error(error);
    });
});

function toggleTicketDetails() {
    td.value.showDialog();
}
function updateSearchResults(data: JourneySelectorViewModel) {
    searchResults.value = data;
}
function updateSelectedOutbound(data: SelectedFare) {
    selectedOutbound.value = data;
}
function updateSelectedReturn(data: SelectedFare) {
    selectedReturn.value = data;
}
</script>

<template>
    <div class="journey-selection v-container">
        <div class="column tickets">
            <JourneySelector
                ref="journeySelector"
                :request="request"
                @searchResults="updateSearchResults($event)"
                @selectedOutbound="updateSelectedOutbound($event)"
                @selectedReturn="updateSelectedReturn($event)"
                :key="requestToString(request)"></JourneySelector>
            <TicketSummary
                v-if="searchResults !== null"
                @toggleTicketDetails="toggleTicketDetails"
                :searchResults="searchResults"
                :selectedOutbound="selectedOutbound"
                :selectedReturn="selectedReturn"></TicketSummary>
        </div>
    </div>
</template>
<style scoped>

</style>

<script setup lang="ts">
import type { IGQLBookingOverview } from "@/models/graphql/BookingOverviewResponse";
import { DateTime } from "luxon";

import { computed, defineProps } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps<{
        bookingData: IGQLBookingOverview | null
    }>();

const expiryDate = computed(() => {
    // check if the outwardDeparture of any of the trips' legs is before the expiry
    const trips = props.bookingData?.trips;
    // we will use Luxon DateTime
    // new Date(Math.min(...trips.map((trip) => new Date(trip.outboundJourney.legs[0].outwardDeparture).getTime())));
    const minDate = trips?.reduce((min, trip) => {
        const tripDate = DateTime.fromISO(trip.outboundJourney.legs[0].departing);
        return tripDate < min ? tripDate : min;
    }, DateTime.fromMillis(Number.MAX_SAFE_INTEGER))!;

    const expiryOnBooking = DateTime.fromISO(props.bookingData?.bookingExpiration!);
    // convert to local time
    if (minDate < expiryOnBooking) {
        return minDate;
    }

    // if this is now before NOW, refresh
    if (expiryOnBooking < DateTime.now()) {
        window.location.reload();
    }

    return expiryOnBooking;
});

const relativeExpiryDate = computed(() => expiryDate.value.toRelative());

</script>
<template>
    <div class="ml-3 basket-expiry mb-5"><font-awesome-icon icon="fa-hourglass" class="mr-2"></font-awesome-icon> Basket will expire {{ relativeExpiryDate }}</div>
</template>

<style scoped lang="scss">
.basket-expiry {
    height: 36px;
    display: inline-grid;
    grid-template-columns: min-content auto;
    line-height: normal;
    vertical-align: middle;
    align-items: center;
}
</style>

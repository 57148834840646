import type { ProblemDetail, ValidationProblemDetail } from "@/api/ProblemDetail";

// Function to serialise the errors property (Map object) of the ValidationProblemDetail  to a useful output
// as a Map object doesn't serialise anything by default, so the errors property would be empty
function replacer(key: string, value: any) {
    const result = value;
    if (value instanceof Map) {
        const obj: { [key: string]: any } = {};
        for (const [mapKey, mapValue] of value) {
            obj[mapKey] = mapValue;
            Object.assign(result, obj);
        }
    }
    return result;
}

export function logApiError(problemDetails: ProblemDetail | ValidationProblemDetail): void {
    console.error(`API error: ${JSON.stringify(problemDetails, replacer, "\t")}`);
}

<script setup lang="ts">
import { handleError } from "@/services/AlertService";
import type { Ref } from "vue";
import { onMounted, ref, watch } from "vue";
import { gqlBookingService } from "@/services/GQLService";
import { useRoute } from "vue-router";
import { useDateFormat } from "@vueuse/core";
import { getDateDiff } from "@/utilities/DateFunctions";
import { JourneyViewType } from "@/models/basket/JourneyViewType";
import TicketDetails from "@/components/3-Basket/TicketDetails.vue";
import { getFareForFareKey } from "@/utilities/FareFromKey";
import { getTicketDetails, getRestrictionDetails } from "@/utilities/GetTicketDetails";
import type { DeliveryMethods } from "@/models/basket/DeliveryMethods";
import { useBookingData } from "@/stores/booking-storage";
import type { FaresByKeyModel, TicketRestrictionSummary, TicketDetailsByKey } from "@/models/JourneySelectorViewModel";
import type {
    IBookingOverviewResponse, IGQLBookingOverview, IGQLTripsOverview, ITicketKey,
} from "@/models/graphql/BookingOverviewResponse";
import { formatPassengers } from "@/utilities/PassengerFormatter";

const isLoading: Ref<boolean> = ref(false);
const route = useRoute();
const bookingOverviewDetails: Ref<IBookingOverviewResponse | null> = ref(null);
const bookingData: Ref<IGQLBookingOverview | null> = ref(null);
const journeyTypeSelection: Ref<JourneyViewType> = ref(JourneyViewType.Outbound);
const deliveryMethod: Ref<DeliveryMethods | string> = ref("");
const ticketDetails: Ref<TicketDetailsByKey | null> = ref(null);
const fareDetails: Ref<FaresByKeyModel | null> = ref(null);
const td: Ref = ref(null);
const ticketRestrictions: Ref<TicketRestrictionSummary | null> = ref(null);
const bookingStore = useBookingData();

async function getBookingDetails() {
    isLoading.value = true;
    const bookingID = bookingStore.checkExistingBooking();
    try {
        const overviewResponse = await gqlBookingService.getGQLBookingOverview(bookingID!);
        bookingOverviewDetails.value = overviewResponse;
        bookingStore.bookingData = overviewResponse.booking;
        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        handleError(error);
    }
}

function setBookingOverview() {
    bookingData.value = bookingOverviewDetails.value?.booking!;
    deliveryMethod.value = route.query.deliveryMethod as string;
}

function formatDate(date: string | undefined) {
    return useDateFormat(date, `ddd${","} DD MMMM HH:mm`).value;
}

function formatTime(date: string | undefined) {
    return useDateFormat(date, "HH:mm").value;
}

function toggleTicketDetail(selectedTicketTicket: ITicketKey, item: IGQLTripsOverview, journeyType: JourneyViewType) {
    const fareKey = selectedTicketTicket.fareKey as string;
    const fareDetailsKey = getFareForFareKey(fareKey, item.fares);
    const ticketDetail = getTicketDetails(bookingData?.value!, fareDetailsKey);
    journeyTypeSelection.value = journeyType;
    fareDetails.value = fareDetailsKey!;
    ticketDetails.value = ticketDetail!.value!;
    const restrictionKey = fareDetails.value.restrictionCode;
    if (restrictionKey !== "") {
        ticketRestrictions.value = getRestrictionDetails(bookingData.value!, restrictionKey);
    }
    td.value.showDialog();
}

async function deleteBooking(id: string) {
    const bookingID = route.query.bookingId as string;
    isLoading.value = true;
    try {
        await gqlBookingService.deleteGQLTrip(bookingID, id);
        const overviewResponse = await gqlBookingService.getGQLBookingOverview(bookingID);
        bookingOverviewDetails.value = overviewResponse;
        bookingStore.bookingData = overviewResponse.booking;
        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        handleError(error);
    }
}

onMounted(() => {
    getBookingDetails();
});

watch(bookingOverviewDetails, () => setBookingOverview(), { immediate: true });
</script>

<template>
    <div>
        <v-overlay v-model="isLoading"
                   contained
                   class="align-center justify-center">
            <v-progress-circular
                indeterminate
                :size="70"
                :width="7"
                color="primary">
            </v-progress-circular>
        </v-overlay>
        <v-container class="pa-0 pa-md-2 pb-5">
            <div class="checkout-ticket-card pb-5" v-for="(item, i) in bookingData?.trips" v-bind:key="i">
                <h2 class="pb-5">Your trip to <b>{{ item.outboundJourney.destinationName }}</b></h2>
                <v-card>
                    <v-card-title class="ticket-head mt-2">
                        <div class="delivery-method" v-if="deliveryMethod === 'tod'">
                            <font-awesome-icon icon="fa-computer-classic" class="fa-lg computer pr-3"></font-awesome-icon>
                            Ticket on Departure
                        </div>
                        <div class="delivery-method" v-else>
                            <font-awesome-icon icon="fa-qrcode" class="fa-lg computer pr-3"></font-awesome-icon>
                            eTicket
                        </div>
                        <button class="pr-3 pl-3" v-if="bookingData && bookingData?.trips.length > 1"  @click="deleteBooking(item.id)">
                            <font-awesome-icon icon="fa-trash" class="pr-3"></font-awesome-icon>Delete
                        </button>
                    </v-card-title>
                    <div class="ticket-type pl-3 pr-3 pb-1 pt-1">
                        <span v-if="item.inboundJourney !== null">Return</span>
                        <span v-else>Single</span>
                    </div>
                    <v-card-text>
                        <div class="journey-summary pb-3">
                            <b>{{ item.outboundJourney.originName }}</b> to <br>
                            <b>{{ item.outboundJourney.destinationName }}</b>
                        </div>
                        <div class="pb-5 journey">
                            <div class="pb-2">
                                <span class="pr-2">OUT</span> {{formatDate(item.outboundJourney.legs[0].departing)}}
                            </div>
                            <div class="time">
                                <div class="pr-3">{{formatTime(item.outboundJourney.legs[0].departing)}}</div>
                                <font-awesome-icon icon="fa-arrow-right"></font-awesome-icon>
                                <div class="pl-3">{{formatTime(item.outboundJourney.legs[0].arriving)}}</div>
                            </div>
                            <div class="duration pt-2">
                                {{item.outboundJourney.changes}} changes, {{ getDateDiff(item.outboundJourney.legs[0].departing, item.outboundJourney.legs[item.outboundJourney.legs.length - 1].arriving) }}
                            </div>
                            <div v-if="item.inboundJourney !== null" class="pt-5">
                                <div class="pb-2">
                                    <span class="pr-2">RETURN</span>{{formatDate(item.inboundJourney.legs[0].departing)}}
                                </div>
                                <div class="time">
                                    <div class="pr-3">{{formatTime(item.inboundJourney.legs[0].departing)}}</div>
                                    <font-awesome-icon icon="fa-arrow-right"></font-awesome-icon>
                                    <div class="pl-3">{{formatTime(item.inboundJourney.legs[0].arriving)}}</div>
                                </div>
                                <div class="duration pt-2">
                                    {{item.inboundJourney.changes}} changes, {{ getDateDiff(item.inboundJourney.legs[0].departing, item.inboundJourney.legs[item.inboundJourney.legs.length - 1].arriving) }}
                                </div>
                            </div>
                        </div>
                        <v-divider></v-divider>
                        <div class="pt-3">Tickets:</div>
                        <div><b>Out: </b>
                            <a href="#" v-for="(ticket, j) in item.outboundTickets" :key="j"  @click="toggleTicketDetail(ticket, item, JourneyViewType.Outbound)">
                                {{ getFareForFareKey(ticket.fareKey, item.fares)?.displayName }}
                            </a>
                        </div>
                        <div v-if="item.inboundJourney !== null">
                            <b>Return: </b>
                            <a href="#" v-for="(ticket, j) in item.inboundTickets" :key="j" @click="toggleTicketDetail(ticket, item, JourneyViewType.Inbound)">
                                {{ getFareForFareKey(ticket.fareKey, item.fares)?.displayName }}
                            </a>
                        </div>
                        <div class="passengers pt-3 pb-3">
                            <font-awesome-icon icon="fa-user-friends"></font-awesome-icon>
                            {{formatPassengers(item.adults, false)}} <span v-if="item.children > 0">{{formatPassengers(item.adults, true)}}</span>
                        </div>
                        <v-divider></v-divider>
                        <div class="pt-5 price">
                            Journey total
                            <span><b>{{ item.totalPriceFormatted }}</b></span>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
            <v-card class="pa-5">
                <div class="price">
                    Booking fee
                    <span><b>{{ bookingData?.bookingFeeFormatted }}</b></span>
                </div>
                <div class="pt-5 price">
                    Amount to pay
                    <span><b>{{ bookingData?.totalPaymentAmountFormatted }}</b></span>
                </div>
            </v-card>
        </v-container>
        <TicketDetails ref="td" :ticket-detail="ticketDetails!" :journey-fare="fareDetails" :fare-type="journeyTypeSelection" :restrictionDetails="ticketRestrictions"></TicketDetails>
    </div>
</template>

<style scoped lang="scss">
.checkout-ticket-card {
    width: 100%;
}
.ticket-type {
    position: relative;
    width: fit-content;
    background-color: var(--vt-c-orange);
    border-radius: 0px 5px 5px 0px;
}
.journey-summary {
    font-size: 1.25rem;
    line-height: 1.25;
}
.v-card {
    border: 1px solid var(--vt-c-lightgray);
    box-shadow: none;
    .v-card-text {
        line-height: 1.25rem;
    }
    .v-card-title {
        button {
            float: right;
            font-size: 1rem;
            background-color: var(--vt-light-gray);
            border-radius: 5px;
        }
    }
}

.delivery-method {
    float: left;
}
.time {
    display: inline-flex;
    font-size: 1.25rem;
    div {
        font-weight: 700;
    }
}
.duration {
    font-style: italic;
}
.journey span {
    font-size: 1.2rem;
}
.price {
    font-size: 1rem;
    span {
        float: right;
    }
}
.v-card-title.ticket-head {
    padding: 5px 10px;
}

@media only screen and (max-width: 760px) {
    .checkout-ticket-card {
        width: 100%;
    }
}
</style>

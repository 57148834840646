import type {
    IGQLBookingOverview, ITicketDetailsCollection, TicketRestrictionsByKey, TicketRestrictionSummary,
} from "@/models/graphql/BookingOverviewResponse";
import type { FaresByKeyModel } from "@/models/JourneySelectorViewModel";

export function getTicketDetails(bookingData: IGQLBookingOverview, fareDetailsKey: FaresByKeyModel) {
    const ticketDetails = bookingData?.ticketDetailsCollection.find((x: ITicketDetailsCollection) => x.key === fareDetailsKey!.ticketDetailsKey);
    return ticketDetails;
}

export function getRestrictionDetails(bookingData: IGQLBookingOverview, restrictionKey: string): TicketRestrictionSummary {
    const restrictionCollection = bookingData!.ticketRestrictionsCollection!.find((x: TicketRestrictionsByKey) => x.key === restrictionKey);
    return restrictionCollection?.value!;
}

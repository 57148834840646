<script setup lang="ts">
import {
    toRefs, onMounted, ref, computed, defineEmits,
} from "vue";
import type { Stripe, StripeElements as SE } from "@stripe/stripe-js";
import type { StripeError } from "@stripe/stripe-js/dist/stripe-js/stripe";
import type { Ref } from "vue";
import { useRouter } from "vue-router";
import { stripeService } from "@/services/StripeService";

import { StripeConstants } from "@/utilities/StripeConstants";

import { StripeElements, StripeElement } from "vue-stripe-js";
import { usePaymentStore } from "@/stores/payment";

const props = withDefaults(defineProps<{
    bookingId: string
  }>(), {
    bookingId: "",
});

const { bookingId } = toRefs(props);
const isLoading: Ref<boolean> = ref(false);

const secret: Ref<string|null> = ref(null);
const customerSecretSession: Ref<string|null> = ref(null);

const paymentErrorToDisplay: Ref<StripeError | null> = ref(null);

const store = usePaymentStore();

const elementsOptions = computed(() => ({
    clientSecret: secret.value,
    customerSessionClientSecret: customerSecretSession.value,
}));

const router = useRouter();

const paymentElement: Ref<typeof StripeElement|null> = ref(null);
const parentElement: Ref<typeof StripeElements | null> = ref(null);

// once we're mounted, we'll call Rocket to get the client secret
onMounted(async () => {
    const clientSecretResponse = await stripeService.createStripeSession(bookingId.value);
    if (clientSecretResponse.customerSecretSession) {
        customerSecretSession.value = clientSecretResponse.customerSecretSession;
    }
    secret.value = clientSecretResponse.sessionId;
});
const stripeKey = StripeConstants.PublishableKey;
const emit = defineEmits(["payment-successful"]);

const isLoadingStripe: Ref<boolean> = ref(true);

const ready = () => {
    isLoadingStripe.value = false;
};

const payAction = async () => {
    isLoading.value = true;
    // @ts-ignore-next-line
    const stripeInstance = parentElement.value.instance as Stripe;
    // build absolute URL using vue-router
    const route = router.resolve("/");
    const absoluteURL = new URL(route.href, window.location.origin).href;
    const { error, paymentIntent } = await stripeInstance.confirmPayment({
    // @ts-ignore-next-line
        elements: parentElement.value.elements as SE,
        confirmParams: {
            return_url: absoluteURL,
        },
        redirect: "if_required",
    });
    isLoading.value = false;
    if (error !== undefined) {
        paymentErrorToDisplay.value = error;
    } else {
        emit("payment-successful", paymentIntent);
    }
};

// emits, paymentSuccessful (with PI)

</script>

<template>
    <div>
        <StripeElements
            v-if="store.stripeReady && secret"
            :class="isLoadingStripe ? 'loading-stripe' : ''"
            v-slot="{ elements }"
            ref="parentElement"
            :stripe-key="stripeKey"
            :elements-options="elementsOptions"
        >
            <StripeElement @ready="ready" ref="paymentElement" type="payment" :elements="elements"></StripeElement>
        </StripeElements>
        <div>
            <v-expand-transition>
                <!-- vuetify button to pay -->
                <v-btn class="mt-4 primary-button" :disabled="isLoading" @click="payAction" v-show="!isLoadingStripe">
                    <font-awesome-icon icon="fa-lock fa-solid" class="mr-2"></font-awesome-icon>
                    Pay Securely <v-progress-circular indeterminate size="15" class="ml-1" v-if="isLoading"></v-progress-circular>
                </v-btn>
            </v-expand-transition>
        </div>
        <div v-if="paymentErrorToDisplay">
            <v-alert
                border="start"
                density="compact"
                variant="flat"
                color="var(--vt-c-warning)"
                class="mt-4"
            >
                <span v-if="paymentErrorToDisplay.message">{{ paymentErrorToDisplay.message }}</span>
                <span v-else>Something went wrong, code: <samp>{{ paymentErrorToDisplay.code }}</samp></span>
            </v-alert>
        </div>

    </div>
</template>
<style scoped lang="scss">
.loading-stripe {
  min-height: 147px;
}
</style>

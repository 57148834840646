<script setup lang="ts">

import { ref, type Ref, toRefs } from "vue";
import { getDateDiff, formatTime } from "@/utilities/DateFunctions";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { TransportModes } from "@/models/basket/TransportModes";
import { StopTypes } from "@/models/basket/StopTypes";
import type { IGQLLeg, IGQLStop } from "@/models/graphql/BookingOverviewResponse";

const props = defineProps<{
    leg: IGQLLeg,
    legIndex: number,
    places: [IGQLStop],
    containsBusReplacement?: boolean
}>();

const {
    leg, legIndex, containsBusReplacement,
} = toRefs(props);

const isCallingPointsExpanded: Ref<boolean> = ref(false);

function toggleStationView(): void {
    isCallingPointsExpanded.value = !isCallingPointsExpanded.value;
}

function getIconForLegMode(legToUse: IGQLLeg): string {
    switch (legToUse.mode) {
        case TransportModes.Walk:
            return "walking";
        case TransportModes.Tube:
            return "subway-tunnel";
        default:
            return legToUse.mode.toString().toLowerCase();
    }
}

</script>

<template>

    <v-timeline align="start" side="end" truncate-line="both">

        <v-timeline-item dot-color="var(--vt-c-orange)" size="x-small">
            <template v-slot:opposite>
                <div :id="`leg-${legIndex}-departs-time`" class="font-weight-bold">
                    {{ formatTime(leg.departing) }}
                </div>
            </template>
            <div class="font-weight-bold d-flex justify-start flex-wrap text-start">
                {{ leg.originName }}
            </div>
            <div>
                <div v-if='containsBusReplacement && leg.mode === TransportModes.Bus'>
                    <v-alert
                        :id="`leg-${legIndex}-bus-replacement`"
                        width="70"
                        class="mt-1 text-center"
                        density="compact"
                        variant="flat"
                        color="var(--vt-c-warning)"
                        text="Bus">
                    </v-alert>
                </div>

                <div :id="`leg-${legIndex}-service-details`"
                     v-if="leg.train !== null && leg.mode === TransportModes.Train">
                    {{ leg.train.description }} to {{ leg.destinationName }}
                </div>
                <div :id="`leg-${legIndex}-tube-details`" class="text-cen"
                     v-if="leg.mode === TransportModes.Tube">
                </div>
                <div :id="`leg-${legIndex}-departing-platform`"
                     class="platform"
                     v-if="leg.mode == TransportModes.Train">
                    Platform 9¾
                </div>
            </div>
        </v-timeline-item>

        <v-timeline-item dot-color="white" size="x-small">
            <template v-slot:opposite>
                <div class="text-center">
                    <font-awesome-icon :id="`leg-${legIndex}-image`"
                                       class="fa-2x" :icon='`fa-${getIconForLegMode(leg)}`'></font-awesome-icon>
                </div>

            </template>
            <div>
                <div :id="`leg-${legIndex}-journey-time`" class="d-lg-inline">
                    {{ getDateDiff(leg.departing, leg.arriving) }}
                </div>

                <div class="d-lg-inline d-xs-none">
                    <a
                        :id="`leg-${legIndex}-calling-points-toggle`"
                        @click="toggleStationView()"
                        v-if="leg.stops &&
                            leg.stops.filter((v) => v.name !== leg.originName &&
                                v.name !== leg.destinationName &&
                                v.typeOfStop == StopTypes.NormalStop)
                                .length > 0">
                        Calling Points
                        <font-awesome-icon :rotation="isCallingPointsExpanded ? 180 : undefined"
                                           icon="fa-chevron-down"></font-awesome-icon>
                    </a>
                </div>

            </div>
        </v-timeline-item>

        <!-- eslint-disable vue/no-use-v-if-with-v-for -->
        <v-timeline-item
            :class="`leg-${legIndex}-calling-points stop-type-${stop.typeOfStop.toString().toLowerCase()}`"
            dot-color="white"
            size="x-small"
            v-if="leg.stops"
            v-for="(stop, stopIndex) in leg.stops.filter((v) => v.name !== leg.originName &&
                v.name !== leg.destinationName &&
                v.typeOfStop == StopTypes.NormalStop)"
            :key="stopIndex"
            v-show="isCallingPointsExpanded">
            <template v-slot:opposite>
                {{ formatTime(stop.arrives) }}
            </template>
            <div>
                {{ stop.name }}
            </div>
        </v-timeline-item>
        <!-- eslint-enable vue/no-use-v-if-with-v-for -->

        <v-timeline-item dot-color="var(--vt-c-orange)" size="x-small">
            <template v-slot:opposite>
                <div :id="`leg-${legIndex}-arrival-time`" class="font-weight-bold">
                    {{ formatTime(leg.arriving) }}
                </div>
                <!--
                    should I remove or keep as a placeholder ?!
                    <div
                    :id="`leg-${legIndex}-arriving-status`"
                    v-if="leg.liveStatus"
                    :class="`status-${leg.liveStatus.arrivingStatus.delayed
                        ? 'delayed'
                        : leg.liveStatus.arrivingStatus.cancelled
                            ? 'cancelled'
                            : 'ontime'}`">
                    {{
                        leg.liveStatus.arrivingStatus.delayed
                            ? "Delayed"
                            : leg.liveStatus.arrivingStatus.cancelled
                                ? "Cancelled"
                                : "On time"
                    }}
                </div>-->
            </template>
            <div class="font-weight-bold">
                {{ leg.destinationName }}
            </div>
            <div>
                <div :id="`leg-${legIndex}-arriving-platform`"
                     class="platform"
                     v-if="leg.mode == TransportModes.Train">
                    Platform 9¾
                </div>
            </div>
        </v-timeline-item>

    </v-timeline>

</template>

<style scoped lang="scss">

.v-timeline--vertical.v-timeline {
    grid-row-gap: 15px;
}

.v-timeline {
    justify-content: start;
}

:deep(.v-timeline-item__opposite) {
    min-width: 90px;
}
.v-timeline-item__opposite {
    max-width: 160px;
    text-align: center;
}
.station-destination td, .station-origin td {
    font-weight: 700;
}

.status-ontime {
    color: green;
}

.status-delayed, .status-cancelled {
    color: var(--vt-c-red);
}
.platform {
    display:none;
}

</style>

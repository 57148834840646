<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref, defineEmits } from "vue";
import type { Ref } from "vue";
import { fetchAuthSession } from "aws-amplify/auth";
import type { FulfilmentRequestResponse } from "@/models/checkout/FulfilmentRequestModel";
import StripeWidget from "@/components/4-Checkout/StripeWidget.vue";
import CheckoutRhs from "@/components/4-Checkout/CheckoutRhs.vue";
import PaymentConfirmation from "./PaymentConfirmation.vue";

const route = useRoute();
const router = useRouter();
const bookingData: Ref<FulfilmentRequestResponse | null> = ref(null);
const userEmail: Ref<string> = ref("");
const paymentConfirmed: Ref<boolean> = ref(false);
const isLoading: Ref<boolean> = ref(false);

const bookingId = route.query.bookingId as string;

const emit = defineEmits(["payment-successful"]);

function submitPayment(paymentIntent: any) {
    emit("payment-successful", paymentIntent);
}

onMounted(async () => {
    await router.isReady();
    isLoading.value = true;
    const session = await fetchAuthSession();
    if (session.tokens !== undefined) { // if we're logged in
        userEmail.value = session.tokens.signInDetails!.loginId!;
    }
    isLoading.value = false;
});

</script>

<template>
    <div>
        <v-overlay v-model="isLoading"
                   contained
                   class="align-center justify-center">
            <v-progress-circular
                :indeterminate="true"
                :size="70"
                :width="7"
                color="primary">
            </v-progress-circular>
        </v-overlay>
        <v-container>
            <v-col class="col-sm authenticated-checkout">
                <CheckoutRhs></CheckoutRhs>
            </v-col>
            <v-col class="col-sm auth-user-payment">
                <div class="pb-5">&nbsp;</div>
                <StripeWidget :booking-id="bookingId" @payment-successful="submitPayment"></StripeWidget>
            </v-col>
        </v-container>
        <div v-if="paymentConfirmed">
            <PaymentConfirmation
                :email="userEmail"
                :bookingData="bookingData!"></PaymentConfirmation>
        </div>
    </div>
</template>

<style scoped lang="scss">
.v-container {
    display: flex;
    max-width: 1200px;
}
</style>

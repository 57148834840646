<script setup lang="ts">

import { ref, watch } from "vue";
import { useJourneyStore } from "@/stores/journey-store";
import { ViaAvoidType } from "@/models/ViaAvoidType";
import { useField } from "vee-validate";
import type { PlaceItem } from "@/models/basket/PlaceItem";
import GenericStationPicker from "@/components/1-JourneyPlanner/GenericStationPicker.vue";

const store = useJourneyStore();
ref<Array<PlaceItem>>([]);
const viaAvoidPlaceField = useField<PlaceItem>("viaAvoidStation", () => true);
const viaAvoidStation = viaAvoidPlaceField.value;

watch(viaAvoidStation, async (newSelectedStation: PlaceItem) => {
    store.query.viaAvoidStation = newSelectedStation;
});
</script>

<template>
    <div class="row">
        <div class="col-1">
            <label class="form-label">Via / Avoid</label>
        </div>
        <div class="col">
            <div class="horizontal-fields">
                <div class="via-avoid-station-picker-container">
                    <select class="form-control" v-model="store.query.viaAvoid">
                        <option :value="ViaAvoidType.Avoid">{{ ViaAvoidType.Avoid.toString() }}</option>
                        <option :value="ViaAvoidType.Via">{{ ViaAvoidType.Via.toString() }}</option>
                    </select>
                </div>
                <div class="via-avoid-station-container">
                    <GenericStationPicker v-model="viaAvoidStation"></GenericStationPicker>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.via-avoid-station-container {
    position: relative;
}

.via-avoid-station-container svg {
    position: absolute;
    left: 10px;
    top: 11px;
}
:deep(.via-avoid-station-container input) {
    border-radius: 20px;
    width: 292px;
}

.via-avoid-station-picker-container {
  border: 1px solid var(--vt-light-gray);
  border-radius: 20px;
  height: 37px;
  padding: 0 7.5px;
  line-height: 2rem;
}

.via-avoid-station-picker-container select {
  appearance: auto;
}

svg.fa-archway {
  color: var(--color-text);
}
</style>

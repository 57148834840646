import { type IStripeClient, StripeClient } from "@/api/StripeClient";
import ApiClient from "@/api/ApiClient";
import type { StripeSessionModel } from "@/models/checkout/StripeSessionModel";

export class StripeService {
    stripeApiClient: IStripeClient;

    constructor(stripeClient: IStripeClient) {
        this.stripeApiClient = stripeClient;
    }

    async createStripeSession(bookingId: string): Promise<StripeSessionModel> {
        return this.stripeApiClient.getStripeParams(bookingId);
    }
}

const StripeApiClient = new StripeClient(new ApiClient());
export const stripeService = new StripeService(StripeApiClient);

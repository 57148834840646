import { computed, type Ref, ref } from "vue";
import { defineStore } from "pinia";
import type { JourneyDestinationSummary } from "@/models/JourneySelectorViewModel";
import type { Journey } from "@/models/remote/SearchResultsResponse";

export const useTicketSummaryStore = defineStore("ticket-summary", () => {
    const outboundJourneyKey: Ref<string | null> = ref(null);
    const inboundJourneyKey: Ref<string | null> = ref(null);
    const outboundFareType: Ref<string | null> = ref(null);
    const outboundChanges: Ref<number> = ref(0);
    const returnFareType: Ref<string | null> = ref(null);
    const inboundChanges: Ref<number> = ref(0);
    const selectedOutboundSummary: Ref<JourneyDestinationSummary | null> = ref(null);
    const selectedOutboundDetails: Ref<Journey | null> = ref(null);
    const selectedInboundSummary: Ref<JourneyDestinationSummary | null> = ref(null);
    const inboundFurtherInformation: Ref<string | null> = ref(null);
    const outboundFurtherInformation: Ref<string | null> = ref(null);
    // not implemented just yet- need to define types
    const selectedInboundDetails: Ref<Journey | null> = ref(null);
    const isCheapest: Ref<Boolean> = ref(false);
    const selectedFare:Ref<number> = ref(0);
    const returnFare:Ref<number> = ref(0);

    const selectedOutbound = computed(() => selectedOutboundSummary);

    const outboundDetails = computed(() => selectedOutboundDetails);

    const selectedOutboundFare = computed(() => selectedFare);
    const selectedReturnFare = computed(() => returnFare);

    const selectedInbound = computed(() => selectedInboundSummary);

    const inboundDetails = computed(() => selectedInboundDetails);

    const selectedOutboundJourneyKey = computed(() => outboundJourneyKey);

    const selectedOutboundFareType = computed(() => outboundFareType);

    const selectedReturnFareType = computed(() => returnFareType);

    const selectedOutboundChanges = computed(() => outboundChanges);

    const selectedInboundChanges = computed(() => inboundChanges);

    const selectedInboundFurtherDetails = computed(() => inboundFurtherInformation);

    const selectedOutboundFurtherDetails = computed(() => outboundFurtherInformation);

    const selectedInboundJourneyKey = computed(() => inboundJourneyKey);

    const cheapestTicket = computed(() => isCheapest);

    function $reset() {
        outboundJourneyKey.value = null;
        inboundJourneyKey.value = null;
        outboundFareType.value = null;
        outboundChanges.value = 0;
        returnFareType.value = null;
        inboundChanges.value = 0;
        selectedOutboundSummary.value = null;
        selectedOutboundDetails.value = null;
        selectedInboundSummary.value = null;
        inboundFurtherInformation.value = null;
        outboundFurtherInformation.value = null;
        selectedInboundDetails.value = null;
        isCheapest.value = false;
        selectedFare.value = 0;
        returnFare.value = 0;
    }

    return {
        selectedOutboundJourneyKey,
        selectedOutbound,
        cheapestTicket,
        outboundDetails,
        inboundDetails,
        selectedOutboundFare,
        selectedInbound,
        selectedInboundJourneyKey,
        selectedReturnFare,
        selectedReturnFareType,
        selectedOutboundFareType,
        selectedOutboundChanges,
        selectedInboundChanges,
        selectedInboundFurtherDetails,
        selectedOutboundFurtherDetails,
        $reset,
    };
});

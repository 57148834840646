<script setup lang="ts">
import { ref, toRefs, watch } from "vue";
import type { Ref } from "vue";
import { formatCurrency } from "@/utilities/CurrencyFunctions";
import { useRouter } from "vue-router";

const props = withDefaults(defineProps<{
    bookingFee?: number,
    ticketTotal?: number,
    totalPayment?: number,
    bookingId: string,
    deliveryMethod: string,
}>(), {
    bookingFee: 0,
    ticketTotal: 0,
    totalPayment: 0,
});

const { bookingFee } = toRefs(props);
const { ticketTotal } = toRefs(props);
const { totalPayment } = toRefs(props);
const { bookingId } = toRefs(props);
const { deliveryMethod } = toRefs(props);
const acceptedTerms: Ref<boolean> = ref(false);
const toggleModel: Ref<boolean> = ref(false);
const selectedDelivery: Ref<any> = ref(null);

const router = useRouter();

watch(toggleModel, () => {
    acceptedTerms.value = toggleModel.value;
});
watch(deliveryMethod, () => {
    selectedDelivery.value = deliveryMethod.value;
});
function goToCheckout() {
    router.push({ path: "/checkout/", query: { bookingId: bookingId.value, deliveryMethod: selectedDelivery.value } });
}
</script>

<template>
    <div class="cost-summary">
        <v-card class="mt-15 pa-5">
            <h2 class="title">Your order</h2>
            <div class="ticket-total">
                <h3>Ticket total</h3>
                {{ formatCurrency(ticketTotal) }}
            </div>
            <div class="booking-fee">
                <h3>Booking fee</h3>
                {{ formatCurrency(bookingFee) }}
            </div>
            <div class="total-cost">
                <h3>Total cost</h3>
                {{ formatCurrency(totalPayment) }}
            </div>
            <div class="terms-conditions pt-4">
                The National Rail Conditions of Travel (NRCoT) will govern your journey.
                By purchasing, you will be bound by this agreement and our <a href="https://mytrainticket.co.uk/mytrainticket-terms-and-conditions/">terms and conditions.</a>
                For more information on your rights and responsibilities, <a href="https://www.nationalrail.co.uk/travel-information/your-rights-and-obligations-as-a-passenger/">you can read the NRCoT in full online.</a>
            </div>
            <div class="accept-conditions">
                <v-switch
                    v-model="toggleModel"
                    hide-details
                    color="#FF7962"
                    class="pr-4"
                ></v-switch>
                I agree and wish to proceed
            </div>
            <v-btn class="primary-button pa-2"
                   :class="{ 'invalid': !acceptedTerms }"
                   @click="goToCheckout()">Continue to checkout</v-btn>
        </v-card>
    </div>
</template>

<style lang="scss">
.cost-summary {
    font-weight: 500;
    .primary-button {
        width: 100%;
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        cursor: pointer;
        &.invalid {
            pointer-events: none;
            background-color: var(--vt-c-disabled-button);
        }
    }
    .accept-conditions {
        display: inline-flex;
        line-height: 4;
    }
    h3 {
        color: var(--vt-c-deep-red);
        font-weight: 500;
    }
}
h2.title {
    color: var(--vt-c-deep-gray);
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
    padding-bottom: .625rem
}
</style>

<script setup lang="ts">
import { ref, toRefs } from "vue";
import type { Ref } from "vue";
import { useDateFormat } from "@vueuse/core";
import type { IGQLLeg, IGQLReservation, IGQLReservationDetails } from "@/models/graphql/BookingOverviewResponse";

const props = defineProps<{
    reservations: IGQLLeg,
}>();

const { reservations } = toRefs(props);
const dialog: Ref<boolean> = ref(false);

function showDialog() {
    dialog.value = true;
}

defineExpose({
    showDialog,
    dialog, // for testing only
});

function formatSeatDetails(reservation: IGQLReservation) {
    const seatDetails = [];
    if (reservation.seatDirection !== null) {
        seatDetails.push(reservation.seatDirection);
    } else if (reservation.seatPosition !== null) {
        seatDetails.push(reservation.seatPosition);
    }
    return seatDetails.toString();
}

function formatTime(date: string | undefined) {
    return useDateFormat(date, "HH:mm, ddd DD MMMM ").value;
}

function formatSeatAttributes(reservation: IGQLReservation) {
    const attributes = Object.keys(reservation.accommodation).find((key) => reservation.accommodation[key as keyof IGQLReservationDetails] === true);
    return attributes?.toString().replace(/([A-Z])/g, " $1").replace(/,/g, ", ").trim();
}
</script>

<template>
    <v-dialog v-model="dialog" :persistent="true" :scrollable="true" width="800px">
        <v-card>
            <v-card-title class="text-center">
                Reservations
                <a class="float-end" @click="dialog = false">
                    <font-awesome-icon icon="fa-5 fa-circle-xmark"></font-awesome-icon>
                </a>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-for="(item, i) in reservations.reservations" :key="i">
                <div v-if="item.isCountedPlace" class="bg-white border rounded-lg text-center fare-header pa-5">
                    <div class="font-weight-bold">{{ reservations.originName }} to {{ reservations.destinationName }}</div>
                    <div>Departing at {{ formatTime(reservations.departing) }}</div>
                    Counted place reservation<br>
                    You haven't been given a reservation for a specific seat, so you can sit in any vacant, unreserved seat according to the class of your ticket.
                </div>
                <div v-else class="bg-white border rounded-lg pa-2 text-center fare-header">
                    <div class="font-weight-bold">{{ reservations.originName }} to {{ reservations.destinationName }}</div>
                    <div>Departing at {{ formatTime(reservations.departing) }}</div>
                    <div>Coach {{ item.coach }}, seat {{ item.seatNumber }}</div>
                    <div> {{ formatSeatDetails(item) }}</div>
                    <div class="seat-attributes"> {{ formatSeatAttributes(item) }}</div>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn class="primary-button" variant="text" @click="dialog = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">
.card {
    width: 800px;
}

.v-card {
    .v-card-text {
        background-color: var(--vt-light-gray);
        height: 600px;
    }

    .v-divider {
        margin: 0;
    }
}

.card-section {
    background-color: var(--vt-light-gray);
}

.v-card-actions {
    button {
        background-color: var(--vt-c-orange);
        color: #fff;
        text-transform: capitalize;
    }
}
.seat-attributes {
    text-transform: capitalize;
}
.v-card .v-card-text {
    height: fit-content;
}
.fare-header {
    font-size:18px;
}
:deep(.discounts ul li) {
    margin-left: 1.25rem;
}
</style>

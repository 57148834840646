import type { ValidationError } from "@/validators/ValidationError";

export function validateViaAvoid(values: any): ValidationError[] {
    const errorMessages: ValidationError[] = [];

    const hasSelectedStation = (values.viaAvoidStation?.place?.placeId);
    if (!hasSelectedStation) {
        return errorMessages;
    }

    const originStation = values.originStation.place;
    const destinationStation = values.destinationStation.place;
    const viaAvoidStation = values.viaAvoidStation.place;

    if (viaAvoidStation.placeId === originStation.placeId
        || viaAvoidStation.placeId === destinationStation.placeId) {
        errorMessages.push({
            Property: "viaAvoidStation",
            Messages: ["Via/Avoid station cannot be the same as the origin or destination stations."],
        });
    }

    return errorMessages;
}

import type { SelectedFare } from "@/models/JourneySelectorViewModel";

export function getRailcardSelection(selectedOutbound: SelectedFare, selectedReturn?:SelectedFare|null) {
    const outboundRailcards = selectedOutbound?.selectedFare?.railcards;
    const inboundRailcards = selectedReturn?.selectedFare?.railcards;
    if (selectedReturn !== null) {
        const railcards = outboundRailcards?.map((o) => {
            const cards = inboundRailcards!.find((i) => (o.code !== i.code));
            return { ...o, cards };
        });
        return railcards;
    }
    return selectedOutbound?.selectedFare?.railcards;
}
